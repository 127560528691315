;(function (angular, undefined) {
   'use strict'

   angular.module('azure.data').factory('stopData', stopData)

   function stopData($q, AzureAPI, CacheFactory, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('stops')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyStop = 0
      var _keyStopCount = 3

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheStop(stop) {
         if (!stop) {
            return
         }
         var cacheOptions = {}
         // If this is a future stop, expire the cache every hour.
         // TODO: We could get smarter about this caching strategy. For example, we could expire the cache at the next
         // turn of the hour (instead of in 1 full hour), since stop['short-drop'] may change around cutoff
         if (new Date() < new Date(stop.finalizedDelivery)) {
            cacheOptions.maxAge = 1000 * 60 * 60
         }
         return _cache.put(getCacheKey(_keyStop, stop.drop, stop.trip), stop, cacheOptions)
      }

      function cacheStops(stops) {
         stops.forEach(cacheStop)
         return stops
      }

      function cacheRemoveStop(dropId, tripId) {
         _cache.remove(getCacheKey(_keyStop, dropId, tripId))
      }

      //================================================================================
      // Stops
      //================================================================================

      function getStop(dropId, tripId, bypassCache) {
         var cacheKey = getCacheKey(_keyStop, dropId, tripId)

         var stop = _cache.get(cacheKey)
         if (!stop || bypassCache) {
            stop = retryOnce(AzureAPI.stop.query, {
               drop: dropId,
               trip: tripId,
            })
               .then(util.first)
               .then(util.cleanItem)
               .then(cacheStop)

            // cache the promise
            _cache.put(cacheKey, stop, {
               storeOnResolve: false,
            })
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(stop)
      }

      function findStopByDropIdTripId(stops, dropIdTripId) {
         return stops.find(function (stop) {
            return stop && stop.trip === dropIdTripId.tripId && stop.drop === dropIdTripId.dropId
         })
      }

      function getStopsByDropIdTripIds(dropIdTripIds, bypassCache) {
         return $q
            .map(dropIdTripIds, function (dropIdTripId) {
               return _cache.get(getCacheKey(_keyStop, dropIdTripId.dropId, dropIdTripId.tripId))
            })
            .then(function (maybeStops) {
               var stops = []
               var dropIdMisses = []
               var tripIdMisses = []
               maybeStops.forEach(function (maybeStop, index) {
                  if (maybeStop && !bypassCache) {
                     stops.push(maybeStop)
                  } else if (dropIdTripIds[index].dropId && dropIdTripIds[index].tripId) {
                     dropIdMisses.push(dropIdTripIds[index].dropId)
                     tripIdMisses.push(dropIdTripIds[index].tripId)
                  }
               })

               if (dropIdMisses.length === 0) {
                  return maybeStops
               }

               dropIdMisses = util.distinct(dropIdMisses)
               tripIdMisses = util.distinct(tripIdMisses)

               var getStopsPromise
               if (dropIdMisses.length === 1 && tripIdMisses.length === 1) {
                  getStopsPromise = getStop(dropIdMisses[0], tripIdMisses[0], bypassCache).then(function (stop) {
                     return [stop]
                  })
               } else {
                  getStopsPromise = retryOnce(AzureAPI.stop.query, {
                     drop: dropIdMisses.join(),
                     trip: tripIdMisses.join(),
                  }).then(cacheStops)
               }

               return getStopsPromise.then(function (moreStops) {
                  return dropIdTripIds.map(function (dropIdTripIds) {
                     return (
                        findStopByDropIdTripId(stops, dropIdTripIds) || findStopByDropIdTripId(moreStops, dropIdTripIds)
                     )
                  })
               })
            })
      }

      function getStopCount(dropId, includeUndelivered) {
         if (!dropId) {
            return $q.resolve()
         }

         var now = new Date()

         var cacheKey = getCacheKey(_keyStopCount, dropId, includeUndelivered)

         var requestOptions = {
            drop: dropId,
         }

         if (!includeUndelivered) {
            requestOptions.estimatedDeliveryBefore = now.toISOString()
         }

         var stopCount = _cache.get(cacheKey)
         if (stopCount === undefined) {
            stopCount = retryOnce(AzureAPI.stop.count, requestOptions).then(function (response) {
               return response.resource.count
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, stopCount)
         }

         return $q.resolve(stopCount)
      }

      //================================================================================

      return {
         getStop: getStop,
         getStopsByDropIdTripIds: getStopsByDropIdTripIds,
         getStopCount: getStopCount,

         // cache clearing
         clearStop: cacheRemoveStop,
         clear: _cache.removeAll,
      }
   }
})(angular)
