;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('personData', personData)

   function personData($q, AzureAPI, CacheFactory, config, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('people')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyPerson = 0
      var _keyPersonAffiliateReferrals = 1
      var _keyOrderedPackagedProducts = 2

      var _localStorage = CacheFactory.createCache('localPeople', {
         storageMode: 'localStorage',
         maxAge: Number.MAX_VALUE,
      })

      // cache key variables, used for building cache keys (each should be unique)
      var _keyLocalPreference = 0

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cachePerson(person) {
         return _cache.put(getCacheKey(_keyPerson, person.id), person)
      }

      function cacheRemovePerson(personId) {
         _cache.remove(getCacheKey(_keyPerson, personId))
      }

      //================================================================================
      // Price Levels / Rewards Rate
      //================================================================================

      var _priceLevels = Object.freeze({
         retail: 'retail',
         wholesale: 'wholesale',
      })

      var _defaultRewardsRate = 2

      var _defaultPriceSettings = {
         priceLevel: _priceLevels.retail,
         rewardsRate: _defaultRewardsRate,
      }

      //================================================================================
      // Preferences
      //================================================================================

      // Some preferences that were previously used are now obsolete. Data for these
      // obsolete preferences may still exist in users' local storage. Reusing an
      // obsolete preference id for a new preference is not recommended, as it would
      // cause the new preference to inherit the stored value of the obsolete one.

      var _preferences = Object.freeze({
         // PREVIOUSLY USED; NOW OBSOLETE: 0
         // PREVIOUSLY USED; NOW OBSOLETE: 1
         preferListView: 2,
         // PREVIOUSLY USED; NOW OBSOLETE: 3
         organicOnly: 4,
         showAllSizes: 5,
      })

      //================================================================================
      // People
      //================================================================================

      function getPerson(personId, bypassCache) {
         var cacheKey = getCacheKey(_keyPerson, personId)

         var person = _cache.get(cacheKey)
         if (!person || bypassCache) {
            person = retryOnce(AzureAPI.person.get, {
               id: personId,
               inline:
                  'email,order-place-issue,is-drop-coordinator,homeDeliveryDriverDropIds,homeDeliveryDriverCreditsTotal,homeDeliveryDriverCreditsBreakdown',
            })
               .then(util.cleanItem)
               .then(cachePerson)

            // cache the promise
            _cache.put(cacheKey, person, {
               storeOnResolve: false,
            })
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(person)
      }

      function getPersonIsGuest(personId) {
         if (!personId) {
            return $q.resolve(false)
         }
         return getPerson(personId).then(function (person) {
            return person.guest
         })
      }

      function getPeopleByIds(personIds) {
         return $q.map(personIds, function (personId) {
            return getPerson(personId)
         })
      }

      function savePerson(person) {
         return retryOnce(AzureAPI.person.save, person)
            .then(util.cleanItem)
            .then(function () {
               // Do not cache password fields
               delete person.password
               delete person['user-password']
               cachePerson(person)
               return person.id
            })
      }

      function updatePerson(personId, personData) {
         return getPerson(personId).then(function (person) {
            var updatedPerson = angular.extend({}, person, personData)
            // do not allow changing the id
            updatedPerson.id = personId
            return savePerson(updatedPerson)
         })
      }

      function updatePersonNotifications(personId, notificationsData) {
         return getPerson(personId).then(function (person) {
            var updatedPerson = angular.copy(person)
            updatedPerson.notifications = angular.extend({}, person.notifications, notificationsData)
            return savePerson(updatedPerson)
         })
      }

      //================================================================================
      // Ordered Packaged Products
      //================================================================================

      function getOrderedPackagedProducts(personId) {
         var cacheKey = getCacheKey(_keyOrderedPackagedProducts, personId)

         var orderedPackagedProducts = _cache.get(cacheKey)
         if (!orderedPackagedProducts) {
            orderedPackagedProducts = retryOnce(AzureAPI.person.orderedPackagedProducts, {
               id: personId,
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, orderedPackagedProducts)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(orderedPackagedProducts)
      }

      //================================================================================
      // Affiliate
      //================================================================================

      function getAffiliateReferralsPage(personId, start, limit) {
         return retryOnce(AzureAPI['affiliate-referral'].query, {
            person: personId,
            limit: limit,
            start: start,
         })
      }

      function getAffiliateReferrals(personId, bypassCache) {
         var cacheKey = getCacheKey(_keyPersonAffiliateReferrals, personId)

         var affiliateReferrals = _cache.get(cacheKey)
         if (!affiliateReferrals || bypassCache) {
            affiliateReferrals = util.getPagesUntilEnd(
               getAffiliateReferralsPage.bind(null, personId),
               0,
               config.apiLimit
            )

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, affiliateReferrals)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(affiliateReferrals)
      }

      //================================================================================
      // Preferences
      //================================================================================

      function getPreferenceWithDefault(personId, preference, defaultValue) {
         var pref = _localStorage.get(getCacheKey(_keyLocalPreference, personId, preference))
         if (pref === undefined) {
            pref = defaultValue
         }
         return $q.resolve(pref)
      }

      function updatePreference(personId, preference, value) {
         return $q.resolve(_localStorage.put(getCacheKey(_keyLocalPreference, personId, preference), value))
      }

      //================================================================================

      return {
         cachePerson: cachePerson,
         getPerson: getPerson,
         getPersonIsGuest: getPersonIsGuest,
         getPeopleByIds: getPeopleByIds,
         updatePerson: updatePerson,
         updatePersonNotifications: updatePersonNotifications,
         getPreferenceWithDefault: getPreferenceWithDefault,
         updatePreference: updatePreference,

         // Price Levels / Rewards Rate
         priceLevels: _priceLevels,
         defaultPriceSettings: _defaultPriceSettings,

         // Ordered Packaged Products
         getOrderedPackagedProducts: getOrderedPackagedProducts,

         // Affiliate
         getAffiliateReferrals: getAffiliateReferrals,

         // Preferences
         preferences: _preferences,

         // cache clearing
         clear: _cache.removeAll,
         clearPerson: cacheRemovePerson,
      }
   }
})(angular)
