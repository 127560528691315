;(function (angular, undefined) {
   'use strict'

   angular.module('azure.data').factory('dropData', dropData)

   function dropData($q, AzureAPI, CacheFactory, config, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('drops')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyUserDropIds = 0
      var _keyDropMembershipIds = 1
      var _keyDropMembership = 2
      var _keyDrop = 3
      var _keyDropMembershipPromise = 4
      var _keyClosestDropIds = 5

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheDrop(drop) {
         return _cache.put(getCacheKey(_keyDrop, drop.id), drop)
      }

      function cacheDrops(drops) {
         drops.forEach(cacheDrop)
         return drops
      }

      function cacheDropMembership(dropMembership) {
         return _cache.put(getCacheKey(_keyDropMembership, dropMembership.id), dropMembership)
      }

      function cacheDropMemberships(dropMemberships) {
         dropMemberships.forEach(cacheDropMembership)
         return dropMemberships
      }

      function cacheRemoveUser(userId) {
         _cache.remove(getCacheKey(_keyUserDropIds, userId))
         _cache.remove(getCacheKey(_keyDropMembershipIds, userId))
      }

      //================================================================================
      // Utility Functions
      //================================================================================

      function filterActive(items) {
         return items.filter(function (item) {
            return item.active
         })
      }

      //================================================================================
      // Drops
      //================================================================================

      function getDrop(dropId, bypassCache) {
         var cacheKey = getCacheKey(_keyDrop, dropId)

         var drop = _cache.get(cacheKey)
         if (!drop || bypassCache) {
            drop = retryOnce(AzureAPI.drop.get, {
               id: dropId,
               homeDeliveryDriverPreferences: true,
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, drop)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(drop)
      }

      function getDropsByIds(dropIds) {
         return $q
            .mapSettled(dropIds, function (dropId) {
               return getDrop(dropId)
            })
            .then(function (results) {
               // Return drops that were successfully retrieved
               return results.reduce(function (drops, result) {
                  if (result.value) {
                     drops.push(result.value)
                  }
                  return drops
               }, [])
            })
      }

      function getUserDropIds(userId) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyUserDropIds, userId)

         var userDropIds = _cache.get(cacheKey)
         if (!userDropIds) {
            userDropIds = retryOnce(AzureAPI.drop.query, {
               'filter-person': userId,
               limit: config.apiLimit,
               homeDeliveryDriverPreferences: true,
            })
               .then(cacheDrops)
               .then(util.mapToIds)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, userDropIds)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(userDropIds)
      }

      function getUserDrops(userId) {
         if (!userId) {
            return $q.resolve()
         }
         return getUserDropIds(userId).then(getDropsByIds)
      }

      function getUserActiveDrops(userId) {
         if (!userId) {
            return $q.resolve()
         }

         return getUserDrops(userId).then(filterActive)
      }

      function getUserActiveDropIds(userId) {
         if (!userId) {
            return $q.resolve()
         }

         return getUserActiveDrops(userId).then(util.mapToIds)
      }

      function getDropRoutes(dropId) {
         return retryOnce(AzureAPI.route.query, {drop: dropId})
      }

      function updateDrop(dropId, dropData) {
         return getDrop(dropId).then(function (drop) {
            var updatedDrop = angular.extend({}, drop, dropData)
            // do not allow changing the id
            updatedDrop.id = dropId
            return retryOnce(AzureAPI.drop.save, updatedDrop)
               .then(util.cleanItem)
               .then(cacheDrop)
               .then(function () {
                  return dropId
               })
         })
      }

      function getClosestActiveDropIds(lngLat, numberToReturn, excludeClosed) {
         var cacheKey = getCacheKey(_keyClosestDropIds, lngLat, numberToReturn, excludeClosed)
         var dropIds = _cache.get(cacheKey)
         if (!dropIds) {
            var options = {
               sort: 'distance(lon:' + lngLat[0] + '|lat:' + lngLat[1] + ')',
               active: true,
               limit: numberToReturn,
               homeDeliveryDriverPreferences: true,
            }
            if (excludeClosed) {
               options.exclusivity = 'open,semi-open'
            }

            dropIds = retryOnce(AzureAPI.drop.query, options).then(cacheDrops).then(util.mapToIds)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, dropIds)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(dropIds)
      }

      //================================================================================
      // Drop Memberships
      //================================================================================

      function getDropMembership(dropMembershipId) {
         if (!dropMembershipId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyDropMembership, dropMembershipId)

         var dropMembership = _cache.get(cacheKey)
         if (!dropMembership) {
            dropMembership = retryOnce(AzureAPI['drop-membership'].get, {
               id: dropMembershipId,
               inline: 'gratitude-total',
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, dropMembership)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(dropMembership)
      }

      function getDropMembershipsByIds(dropMembershipIds) {
         return $q.map(dropMembershipIds, function (dropMembershipId) {
            return getDropMembership(dropMembershipId)
         })
      }

      function getDropMembershipIds(userId) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyDropMembershipIds, userId)

         var dropMembershipIds = _cache.get(cacheKey)
         if (!dropMembershipIds) {
            dropMembershipIds = retryOnce(AzureAPI['drop-membership'].query, {
               'filter-person': userId,
               limit: config.apiLimit,
               sort: 'drop.name',
               inline: 'gratitude-total',
            })
               .then(cacheDropMemberships)
               .then(util.mapToIds)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, dropMembershipIds)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(dropMembershipIds)
      }

      function getDropMemberships(userId) {
         if (!userId) {
            return $q.resolve()
         }

         return getDropMembershipIds(userId).then(getDropMembershipsByIds)
      }

      function getActiveDropMemberships(userId) {
         if (!userId) {
            return $q.resolve()
         }

         return getDropMemberships(userId).then(filterActive)
      }

      function getDropMembershipByUserIdDropId(userId, dropId) {
         return getDropMemberships(userId).then(function (dropMemberships) {
            return util.findById(dropMemberships, dropId)
         })
      }

      function addDropMembership(userId, dropId, pending) {
         if (!userId) {
            return $q.resolve()
         }

         return getDropMembershipByUserIdDropId(userId, dropId).then(function (dropMembership) {
            if (dropMembership) {
               return updateDropMembership(dropMembership.id, {
                  active: true,
                  pending: pending,
               })
            } else {
               var cacheKey = getCacheKey(_keyDropMembershipPromise, userId, dropId)

               var addDropMembershipPromise = _cache.get(cacheKey)
               if (!addDropMembershipPromise) {
                  addDropMembershipPromise = retryOnce(AzureAPI['drop-membership'].create, {
                     drop: dropId,
                     customer: userId,
                     pending: pending,
                     active: true,
                  }).then(function (response) {
                     _cache.remove(cacheKey)
                     _cache.remove(getCacheKey(_keyDrop, dropId))
                     cacheRemoveUser(userId)
                     return response // For lack of a better thing to return
                  })
                  _cache.put(cacheKey, addDropMembershipPromise, {
                     storeOnResolve: false,
                  })
               }
               return $q.resolve(addDropMembershipPromise)
            }
         })
      }

      function saveDropMembership(dropMembership) {
         return retryOnce(AzureAPI['drop-membership'].save, dropMembership)
            .then(util.cleanItem)
            .then(cacheDropMembership)
            .then(function (dropMembership) {
               return dropMembership.id
            })
      }

      function updateDropMembership(dropMembershipId, dropMembershipData) {
         return getDropMembership(dropMembershipId).then(function (dropMembership) {
            var updatedDropMembership = angular.extend({}, dropMembership, dropMembershipData)
            // do not allow changing the id
            updatedDropMembership.id = dropMembershipId
            return saveDropMembership(updatedDropMembership).then(function (response) {
               if (dropMembership.active !== updatedDropMembership.active) {
                  // If dropMembership.active changed, clear the cached drop because the user now has
                  // different permissions for that drop, so either more or less drop data should be available
                  _cache.remove(getCacheKey(_keyDrop, dropMembership.drop))
                  cacheRemoveUser(dropMembership.customer)
               }
               return response
            })
         })
      }

      function updateDropMembershipNotifications(dropMembershipId, notificationsData) {
         return getDropMembership(dropMembershipId).then(function (dropMembership) {
            dropMembership.notifications = angular.extend({}, dropMembership.notifications, notificationsData)
            return saveDropMembership(dropMembership)
         })
      }

      //================================================================================

      return {
         getUserActiveDropIds: getUserActiveDropIds,
         getUserActiveDrops: getUserActiveDrops,
         getDrop: getDrop,
         getDropRoutes: getDropRoutes,
         getClosestActiveDropIds: getClosestActiveDropIds,
         updateDrop: updateDrop,
         getActiveDropMemberships: getActiveDropMemberships,
         addDropMembership: addDropMembership,
         updateDropMembership: updateDropMembership,
         updateDropMembershipNotifications: updateDropMembershipNotifications,

         // cache clearing
         clearUserCache: cacheRemoveUser,
         clear: _cache.removeAll,
      }
   }
})(angular)
