;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('regionData', regionData)

   function regionData($q, $filter, AzureAPI, $cacheFactory, config, util) {
      var cache = $cacheFactory('regions')

      var retryOnce = util.asyncRetryOnce

      function getRegions() {
         var regions = cache.get('regions')

         if (!regions) {
            regions = retryOnce(AzureAPI.region.query, {
               limit: config.apiLimit,
            }).then(function (regions) {
               // when promise resolves, replace cached promise with resolved object
               return cache.put('regions', regions)
            })

            // cache the promise
            return cache.put('regions', regions)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(regions)
      }

      function getRegionsByCountryIso3(countryIso3) {
         return getRegions().then(function (regions) {
            return $filter('filter')(regions, {
               'country-iso3': countryIso3,
            })
         })
      }

      return {
         getRegions: getRegions,
         getRegionsByCountryIso3: getRegionsByCountryIso3,
      }
   }
})(angular)
