/* jshint -W016 */
;(function (angular, countdown) {
   'use strict'

   /**
    * @ngdoc filter
    * @name countdown
    * @description
    * # countdown
    * Converts a date into a countdown from now
    * Note: this depends on countdownjs: https://github.com/mckamey/countdownjs
    */

   angular.module('azure.filters').filter('countdown', countdownFilter)

   function countdownFilter() {
      countdown.setLabels('|s|m|h|d', '|s|m|h|d', ' ', ' ', ' ')
      return function (date) {
         var now = new Date()
         var units = countdown.DAYS | countdown.HOURS | countdown.MINUTES
         // Add seconds unit if less than 1 minute left
         if (date - now < 60000) {
            units |= countdown.SECONDS
         }
         var timer = countdown(date, now, units).toString()
         return timer
      }
   }
})(angular, /* global countdown */ countdown)
