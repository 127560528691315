;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('notificationData', notificationData)

   function notificationData($q, AzureAPI, CacheFactory, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('notifications')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyNotifications = 0

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheRemoveNotifications(userId) {
         _cache.remove(getCacheKey(_keyNotifications, userId))
      }

      function cacheRemoveNotification(userId, notificationId) {
         var notifications = _cache.get(getCacheKey(_keyNotifications, userId))
         if (notifications) {
            $q.resolve(notifications).then(function (notifications) {
               util.removeById(notifications, notificationId)
            })
         }
      }

      //================================================================================
      // Notifications
      //================================================================================

      function getNotifications(userId) {
         var cacheKey = getCacheKey(_keyNotifications, userId)

         var notifications = _cache.get(cacheKey)
         if (!notifications) {
            notifications = retryOnce(AzureAPI.notification.query, {
               active: true,
               'filter-person': userId,
               'annotate-person': userId,
               dismissed: false,
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, notifications)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(notifications)
      }

      function dismiss(userId, notificationId) {
         return retryOnce(AzureAPI['notification-dismissal'].create, {
            notification: notificationId,
            person: userId,
         }).then(function () {
            cacheRemoveNotification(userId, notificationId)
            return notificationId
         })
      }

      //================================================================================

      return {
         getNotifications: getNotifications,
         dismiss: dismiss,

         // cache clearing
         clearUserCache: cacheRemoveNotifications,
         clear: _cache.removeAll,
      }
   }
})(angular)
