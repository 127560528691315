;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('addressString', addressStringFilter)

   function addressStringFilter($filter) {
      return function (address, includePostalCode) {
         if (!address) {
            return ''
         }

         var addressString

         var maybeExtended = address['extended-address'] && address['extended-address'].trim()
         var maybeStreet = address['street-address'] && address['street-address'].trim()
         var maybeLocality = address.locality && address.locality.trim()

         var tempArray = []
         if (maybeStreet) {
            tempArray.push(maybeStreet)
         }
         if (maybeExtended) {
            tempArray.push(maybeExtended)
         }
         if (maybeLocality) {
            tempArray.push(maybeLocality)
         }

         addressString = tempArray.join(', ')

         if (address.region) {
            if (addressString) {
               addressString += ', ' + $filter('convertPostalStateFormat')(address.region)
            } else {
               addressString += $filter('convertPostalStateFormat')(address.region)
            }

            if (includePostalCode && address['postal-code']) {
               addressString += ' ' + address['postal-code']
            }
         } else if (address.country) {
            addressString += ', ' + address.country
         }

         return addressString
      }
   }
})(angular)
