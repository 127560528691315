;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('firstName', firstNameFilter).filter('lastName', lastNameFilter)

   function splitFullName(fullName) {
      var firstName = ''
      var lastName = ''
      if (fullName) {
         var nameParts = fullName.split(' ')
         if (nameParts.length > 1) {
            lastName = nameParts.pop()
            firstName = nameParts.join(' ')
         } else {
            firstName = nameParts[0]
         }
      }
      return [firstName, lastName]
   }

   function firstNameFilter() {
      return function (input) {
         return splitFullName(input)[0]
      }
   }

   function lastNameFilter() {
      return function (input) {
         return splitFullName(input)[1]
      }
   }
})(angular)
