;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('listCommaSplit', listCommaSplitFilter)

   function listCommaSplitFilter() {
      return function (input) {
         if (!input) {
            return ''
         }

         // Split string on commas not inside parenthesis
         // Example:
         // input string: "Butter, Sugar, Syrup (sugar, water), Milk"
         // output array: ["Butter", "Sugar", "Syrup (sugar, water)", "Milk"]

         return input.split(/(?![^(]*\)),/g)
      }
   }
})(angular)
