;(function (angular) {
   'use strict'

   angular.module('azure.pubSub', []).factory('pubSub', pubSub)

   function pubSub($rootScope) {
      function subscribe(events, callback, scope) {
         if (angular.isArray(events)) {
            return events.map(function (event) {
               return subscribe(event, callback, scope)
            })
         }
         var deregister = $rootScope.$on(events, callback)
         if (scope) {
            scope.$on('$destroy', deregister)
         }
         return deregister
      }

      function publish(eventName, args) {
         $rootScope.$emit(eventName, args)
         return args
      }

      //================================================================================

      return {
         subscribe: subscribe,
         publish: publish,
      }
   }
})(angular)
