;(function (angular) {
   'use strict'

   // credit: https://gist.github.com/ferronrsmith/5630696

   angular.module('azure.filters').filter('ordinal', ordinalFilter)

   function ordinalFilter() {
      return function (input) {
         if (!input) {
            return
         }

         var s = ['th', 'st', 'nd', 'rd']
         var v = input % 100
         return input + (s[(v - 20) % 10] || s[v] || s[0])
      }
   }
})(angular)
