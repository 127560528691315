;(function (angular) {
   'use strict'

   angular.module('azure.util').directive('asToggleClassViaIntObserver', toggleClassViaIntObserver)

   function toggleClassViaIntObserver($timeout, $window) {
      return {
         link: link,
      }

      function link(scope, element, attrs) {
         var _observer
         var _observerTarget
         var _classTarget

         function addOrRemoveAffixedClass(addOrRemove) {
            if (_classTarget) {
               _classTarget.classList[addOrRemove](attrs.toggleClass)
            }
         }

         function initObserver() {
            if (attrs.classTargetId) {
               _classTarget = document.getElementById(attrs.classTargetId)
            } else {
               _classTarget = element[0]
            }

            function toggleClass(entries) {
               // Only add the class if the observer is scrolled above the VP (not below it)
               entries.forEach(function (entry) {
                  if (
                     !entry.isIntersecting &&
                     entry.rootBounds &&
                     entry.boundingClientRect.top <= entry.rootBounds.top
                  ) {
                     addOrRemoveAffixedClass('add')
                  } else {
                     addOrRemoveAffixedClass('remove')
                  }
               })
            }

            var options = {}
            if (attrs.rootMargin) {
               options.rootMargin = attrs.rootMargin
            }
            if (attrs.threshold) {
               options.threshold = parseFloat(attrs.threshold)
            }
            _observer = new $window.IntersectionObserver(toggleClass, options)

            if (attrs.observerTargetId) {
               _observerTarget = document.getElementById(attrs.observerTargetId)
            } else {
               _observerTarget = element[0]
            }

            if (_observer && _observerTarget) {
               _observer.observe(_observerTarget)
            }
         }

         function destroy() {
            if (_observer && _observerTarget) {
               _observer.unobserve(_observerTarget)
               addOrRemoveAffixedClass('remove')
            }
         }

         //================================================================================

         scope.$on('$destroy', destroy)

         //================================================================================
         // Init
         //================================================================================

         function init() {
            if (attrs.observeIf && !scope.$eval(attrs.observeIf)) {
               return
            }

            if ($window.IntersectionObserver) {
               initObserver()
            }
         }

         $timeout(init)
      }
   }
})(angular)
