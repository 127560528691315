;(function (angular) {
   'use strict'

   angular.module('azure.config', []).factory('config', config)

   function config(env) {
      return {
         algoliaIndexNames: env.algoliaIndexNames,
         api: env.beehiveApiUrl,
         beehiveApiV2: env.beehiveApiV2Url,
         apiLimit: env.maximumApiLimit,
         cookieDomain: env.cookieDomain,
         mapbox: {
            token: 'pk.eyJ1IjoiYXp1cmVzb2Z0d2FyZSIsImEiOiJjaWplb3EyeHcwMDBydGdrc2tlcmt1aHd3In0.qzGZiAfhLp4aXYhkTKOHug',
            tokenForAddressAutocomplete:
               'pk.eyJ1IjoiYXp1cmVzb2Z0d2FyZSIsImEiOiJjbTd4OGJlOTkwMmJvMmxzZ204cnM1NXhwIn0.CTpfaB1rkfSldkwsIUw5rQ',
            url: 'https://api.tiles.mapbox.com/v4/{mapid}/{z}/{x}/{y}.png?access_token={apikey}',
            geocodeUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
            geocodeUrlV6: 'https://api.mapbox.com/search/geocode/v6/forward',
            streetId: 'mapbox.streets',
            satId: 'mapbox.satellite',
         },
         recaptchaSiteKey: env.recaptchaSiteKey,
      }
   }
})(angular)
