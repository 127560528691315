;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('trustedHtml', [
      '$sce',
      function ($sce) {
         return function (text) {
            return $sce.trustAsHtml(text)
         }
      },
   ])
})(angular)
