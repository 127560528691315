;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('searchQueryData', searchQueryData)

   function searchQueryData(CacheFactory, util) {
      //================================================================================
      // Cache
      //================================================================================

      var getCacheKey = util.joinArgs

      var _localStorage = CacheFactory.createCache('localSearchQueries', {
         storageMode: 'localStorage',
         maxAge: Number.MAX_VALUE,
      })

      var _keyLocalSearchQueries = 0

      //================================================================================

      function getSearchQueries(userId) {
         var cacheKey = getCacheKey(_keyLocalSearchQueries, userId)
         var maybeExistingQueriesObj = _localStorage.get(cacheKey)
         return maybeExistingQueriesObj || {}
      }

      function saveOrUpdateSearchQuery(userId, query) {
         var queriesObj = getSearchQueries(userId)

         queriesObj[query] = queriesObj[query] + 1 || 1

         _localStorage.put(getCacheKey(_keyLocalSearchQueries, userId), queriesObj)
      }

      function removeSearchQuery(userId, query) {
         var queriesObj = getSearchQueries(userId)

         delete queriesObj[query]

         _localStorage.put(getCacheKey(_keyLocalSearchQueries, userId), queriesObj)
      }

      //================================================================================

      return {
         getSearchQueries: getSearchQueries,
         saveOrUpdateSearchQuery: saveOrUpdateSearchQuery,
         removeSearchQuery: removeSearchQuery,
      }
   }
})(angular)
