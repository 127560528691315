;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('categoryData', categoryData)

   function categoryData($q, AzureAlgolia, AzureAPI, CacheFactory, config, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('categories')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyCategory = 0

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      var _categoryIndex = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.categories)

      function getCategoryFromApi(categoryId) {
         if (!categoryId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyCategory, categoryId)

         var category = _cache.get(cacheKey)

         if (!category) {
            category = retryOnce(AzureAPI.category.get, {
               id: categoryId,
               inline: 'ancestors',
            }).then(function (category) {
               category.depth = (category.ancestors && category.ancestors.length) || 0
               return util.cleanItem(category)
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, category)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(category)
      }

      function getCategories(categoryIds) {
         var categoryIdMisses = []
         var cachedCategories = []
         categoryIds.forEach(function (categoryId) {
            var category = _cache.get(getCacheKey(_keyCategory, categoryId))
            if (category) {
               cachedCategories.push(category)
            } else {
               categoryIdMisses.push(categoryId)
            }
         })

         var categoriesFromAlgoliaPromise
         if (categoryIdMisses.length) {
            categoriesFromAlgoliaPromise = _categoryIndex.getObjects(categoryIdMisses)
         } else {
            categoriesFromAlgoliaPromise = $q.resolve([])
         }

         return categoriesFromAlgoliaPromise.then(function (categoriesFromAlgolia) {
            return $q.mapSettled(categoryIds, function (categoryId) {
               var id = Number(categoryId)
               return (
                  util.findById(cachedCategories, id) ||
                  util.findById(categoriesFromAlgolia, id) ||
                  getCategoryFromApi(id)
               )
            })
         })
      }

      function getCategory(categoryId) {
         return _categoryIndex.getObject(categoryId).then(function (category) {
            return category || getCategoryFromApi(categoryId)
         })
      }

      function searchCategories(query, opts, cacheObjectsFromSearch) {
         return _categoryIndex.search(query, opts, cacheObjectsFromSearch)
      }

      function getCategoriesFromSearch(query, opts, cacheObjectsFromSearch) {
         return searchCategories(query, opts, cacheObjectsFromSearch).then(function (resp) {
            return resp.hits
         })
      }

      function getRootCategories() {
         // Hard-coding root categories to reduce algolia queries per second
         return getCategories([21924, 21244, 19457, 19159, 17257])
            .then(function (results) {
               return results.map(function (result) {
                  return result.value
               })
            })
            .then(util.compact)
      }

      function getCategoriesByParent(parentCategoryId) {
         var opts = {
            attributesToHighlight: '',
            filters: 'parent:' + parentCategoryId,
            hitsPerPage: 1000,
         }
         return getCategoriesFromSearch(null, opts, true)
      }

      //================================================================================

      return {
         getCategories: getCategories,
         getCategory: getCategory,
         getRootCategories: getRootCategories,
         getCategoriesByParent: getCategoriesByParent,
         searchCategories: searchCategories,
         getCategoriesFromSearch: getCategoriesFromSearch,
      }
   }
})(angular)
