;(function (angular) {
   'use strict'

   angular.module('azure.util').directive('asBindHtmlCompile', bindHtmlCompile)

   function bindHtmlCompile($compile, $sce) {
      return {
         restrict: 'A',
         link: bindHtmlCompileLink,
      }

      function bindHtmlCompileLink(scope, element, attrs) {
         // The expression in the as-bind-html-compile attribute is evaluated
         // once, so this is a built-in one-time binding
         var html = scope.$eval(attrs.asBindHtmlCompile)
         if (html) {
            element.html($sce.valueOf(html))
            $compile(element.contents())(scope)
         }
      }
   }
})(angular)
