;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('emailData', emailData)

   function emailData($q, AzureAPI, CacheFactory, config, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('emails')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyUserEmails = 0

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheEmail(userId, email) {
         var emails = _cache.get(getCacheKey(_keyUserEmails, userId))
         if (emails) {
            $q.resolve(emails).then(function (emails) {
               // Get index of email to update (-1 if no index found)
               var indexToUpdate = util.findIndexById(emails, email.id)

               // If no index, email doesn't yet exist in cache... add it
               if (indexToUpdate < 0) {
                  emails.push(email)

                  // Otherwise, update it
               } else {
                  emails[indexToUpdate] = email
               }
            })
         }
      }

      function cacheRemoveEmail(userId, emailId) {
         var emails = _cache.get(getCacheKey(_keyUserEmails, userId))
         if (emails) {
            $q.resolve(emails).then(function (emails) {
               util.removeById(emails, emailId)
            })
         }
      }

      function cacheRemoveUser(userId) {
         _cache.remove(getCacheKey(_keyUserEmails, userId))
      }

      //================================================================================
      // Emails
      //================================================================================

      function getUserEmails(userId) {
         var cacheKey = getCacheKey(_keyUserEmails, userId)
         var emails = _cache.get(cacheKey)

         if (!emails) {
            emails = retryOnce(AzureAPI.email.query, {
               'filter-person': userId,
               limit: config.apiLimit,
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            return _cache.put(cacheKey, emails)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(emails).then(function (emails) {
            return util.sortByPropertyDesc('preference', emails)
         })
      }

      function getUserEmail(userId, emailId) {
         return getUserEmails(userId).then(function (emails) {
            return util.findById(emails, emailId)
         })
      }

      function addEmail(userId, emailToAdd, password) {
         return retryOnce(AzureAPI.email.create, {
            email: {
               address: emailToAdd,
               person: userId,
               preference: 0,
            },
            'user-password': password,
         })
            .then(util.cleanItem)
            .then(function (responseEmail) {
               cacheEmail(userId, responseEmail)
            })
      }

      function deleteEmail(userId, emailId) {
         return retryOnce(AzureAPI.email.delete, {
            id: emailId,
         }).then(function () {
            cacheRemoveEmail(userId, emailId)
         })
      }

      function updateEmail(userId, emailId, emailData, password) {
         return getUserEmail(userId, emailId).then(function (email) {
            var updatedEmail = {
               id: emailId,
               email: angular.extend({}, email, emailData),
               'user-password': password,
            }
            return retryOnce(AzureAPI.email.save, updatedEmail)
               .then(util.cleanItem)
               .then(function (responseEmail) {
                  cacheEmail(userId, responseEmail)
               })
         })
      }

      //================================================================================

      return {
         getUserEmails: getUserEmails,
         addEmail: addEmail,
         deleteEmail: deleteEmail,
         updateEmail: updateEmail,

         // cache clearing
         clearUserCache: cacheRemoveUser,
         clear: _cache.removeAll,
      }
   }
})(angular)
