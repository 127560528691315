;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('dollars', dollarsFilter)

   function dollarsFilter($filter) {
      return function (value, doNotStripZeroCents, fractionSize) {
         /* jshint eqnull: true */
         if (value == null) {
            return value
         }

         var filteredValue = $filter('currency')(value, '$', fractionSize)
         return doNotStripZeroCents ? filteredValue : filteredValue.replace('.00', '')
      }
   }
})(angular)
