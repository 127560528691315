;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('phone', phoneFilter)

   function phoneFilter() {
      return function (num) {
         if (!num) {
            return ''
         }

         if (num.startsWith('+1 ')) {
            // the number has a country code first
            num = num.slice(3) // remove the country code
         }

         var digits = num.replace(/\D/g, '') // strip non digit chars
         if (digits.length === 10) {
            return '(' + digits.slice(0, 3) + ') ' + digits.slice(3, 6) + '-' + digits.slice(6)
         }
         return num
      }
   }
})(angular)
