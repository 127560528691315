;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('videoUrlEmbed', videoUrlEmbedFilter)

   function videoUrlEmbedFilter($sce) {
      return function (url) {
         if (!url) {
            return
         }

         if (url.includes('youtu')) {
            return $sce.trustAsResourceUrl(ifYoutube(url, true))
         }

         if (url.includes('vimeo')) {
            return $sce.trustAsResourceUrl(ifVimeo(url, true))
         }
      }
   }

   function ifYoutube(url, doNotTrack) {
      var uri = null

      try {
         uri = new URL(url)
      } catch (e) {
         return false
      }

      var path = uri.pathname.split('/').filter(function (item) {
         return item !== ''
      })
      var first = path[0]
      var second = path[1]
      var host = 'https://' + (doNotTrack ? 'www.youtube-nocookie.com' : uri.host) + '/embed'

      var isYoutubeId = function (id) {
         if (!id) {
            return false
         }

         return id.match(/^[a-zA-Z0-9_-]+$/) !== null
      }

      var query = uri.searchParams

      // build the correct base URL for the embed,
      // the query params are appended below
      var src = null
      switch (path.join('/')) {
         case 'embed/videoseries':
         case 'playlist':
            if (isYoutubeId(query.get('list'))) {
               src = host + '/videoseries'
            }
            break
         case 'watch':
            if (isYoutubeId(query.get('v'))) {
               src = host + '/' + query.get('v')

               if (query.has('t')) {
                  query.set('start', query.get('t'))
               }

               query.delete('v')
               query.delete('t')
            }

            break
         default:
            // short URLs
            if (uri.host.includes('youtu.be') && isYoutubeId(first)) {
               src = 'https://www.youtube.com/embed/' + first

               if (query.has('t')) {
                  query.set('start', query.get('t'))
               }

               query.delete('t')
            } else if (first === 'embed' && isYoutubeId(second)) {
               src = host + '/' + second
            }
      }

      if (!src) {
         return false
      }

      var queryString = query.toString()

      if (queryString.length) {
         src += '?' + queryString
      }

      return src
   }

   function ifVimeo(url, doNotTrack) {
      var uri = null

      try {
         uri = new URL(url)
      } catch (e) {
         return false
      }

      var path = uri.pathname.split('/').filter(function (item) {
         return item !== ''
      })

      var query = uri.searchParams
      var id = null

      if (doNotTrack) {
         query.append('dnt', 1)
      }

      switch (uri.host) {
         case 'vimeo.com':
         case 'www.vimeo.com':
            id = path[0]
            break
         case 'player.vimeo.com':
            id = path[1]
            break
      }

      if (!id || !id.match(/^[0-9]*$/)) {
         return false
      }

      var src = 'https://player.vimeo.com/video/' + id

      var queryString = query.toString()

      if (queryString.length) {
         src += '?' + queryString
      }

      return src
   }
})(angular)
