;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('accountEntryData', accountEntryData)

   function accountEntryData($q, $http, config, AzureAPI, CacheFactory, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('accountEntries')
      var _cacheKeysByUser = {}

      // cache key variables, used for building cache keys (each should be unique)
      var _keyAccountEntriesCount = 0
      var _keyAccountEntries = 1
      var _keySpendMetrics = 2
      var _keyPendingPaymentsData = 3

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheRemoveUser(userId) {
         var userCacheKeys = _cacheKeysByUser[userId]
         if (userCacheKeys) {
            userCacheKeys.forEach(function (cacheKey) {
               _cache.remove(cacheKey)
            })
            delete _cacheKeysByUser[userId]
         }
      }

      function cacheCacheKey(userId, cacheKey) {
         var userCacheKeys = _cacheKeysByUser[userId] || new Set()
         _cacheKeysByUser[userId] = userCacheKeys
         userCacheKeys.add(cacheKey)
      }

      //================================================================================
      // AccountEntries
      //================================================================================

      function getLastOne(userId, bypassCache) {
         return getAccountEntries(userId, -1, 1, undefined, bypassCache).then(util.first)
      }

      function getAccountEntries(userId, start, limit, apiFilterParams, bypassCache) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyAccountEntries, userId, start, limit, JSON.stringify(apiFilterParams))

         var accountEntries = _cache.get(cacheKey)
         if (!accountEntries || bypassCache) {
            var params = {
               'filter-person': userId,
               balance: true,
               limit: limit || config.apiLimit,
            }
            if (start) {
               params.start = start
            }
            params = addMaybeHistoryFilterParamsToRequestParams(params, apiFilterParams)

            accountEntries = retryOnce(AzureAPI['account-entry'].query, params)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, accountEntries)
            cacheCacheKey(userId, cacheKey)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(accountEntries)
      }

      function downloadAccountEntries(userId, apiFilterParams) {
         if (!userId) {
            return $q.resolve()
         }

         var params = {
            'filter-person': userId,
            csv: true,
         }

         params = addMaybeHistoryFilterParamsToRequestParams(params, apiFilterParams)

         // CONSIDERATION: Consider making this a util function
         var queryString = Object.keys(params)
            .map(function (key) {
               return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            })
            .join('&')

         // Note that using `AzureAPI` here does not work because we need to set the response type to `arraybuffer`
         return retryOnce($http, {
            method: 'GET',
            url: config.api + '/account-entries/' + '?' + queryString,
            withCredentials: true,
            responseType: 'arraybuffer',
         })
      }

      function addMaybeHistoryFilterParamsToRequestParams(requestParams, apiFilterParams) {
         if (apiFilterParams) {
            if (apiFilterParams.historyType) {
               requestParams[apiFilterParams.historyType] = true
            }
            if (apiFilterParams.historyStart && apiFilterParams.historyEnd) {
               requestParams['date-after'] = apiFilterParams.historyStart
               requestParams['date-before'] = apiFilterParams.historyEnd
            }
         }
         return requestParams
      }

      function makePayment(userId, payment) {
         return retryOnce(AzureAPI.payment.create, payment).then(function () {
            cacheRemoveUser(userId)
         })
      }

      function getAccountEntriesCount(userId, apiFilterParams) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyAccountEntriesCount, userId, JSON.stringify(apiFilterParams))

         var accountEntriesCount = _cache.get(cacheKey)
         if (accountEntriesCount === undefined) {
            var params = {
               'filter-person': userId,
            }
            params = addMaybeHistoryFilterParamsToRequestParams(params, apiFilterParams)

            accountEntriesCount = retryOnce(AzureAPI['account-entry'].count, params).then(function (response) {
               return response.resource.count
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, accountEntriesCount)
            cacheCacheKey(userId, cacheKey)
         }

         return $q.resolve(accountEntriesCount)
      }

      function getPendingPaymentsState(userId, bypassCache) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyPendingPaymentsData, userId)

         var pendingPaymentsData = _cache.get(cacheKey)
         if (pendingPaymentsData === undefined || bypassCache) {
            pendingPaymentsData = $http({
               method: 'GET',
               url: config.beehiveApiV2 + '/accounts_receivable/pending-payments-state/' + userId,
               withCredentials: true,
            }).then(function (response) {
               return response.data
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, pendingPaymentsData)
            cacheCacheKey(userId, cacheKey)
         }

         return $q.resolve(pendingPaymentsData)
      }

      function getSpendMetrics(userId, bypassCache) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keySpendMetrics, userId)

         var spendMetrics = _cache.get(cacheKey)
         if (!spendMetrics || bypassCache) {
            spendMetrics = retryOnce($http, {
               method: 'GET',
               url: config.beehiveApiV2 + '/accounts_receivable/spend-metrics/' + userId,
               withCredentials: true,
            }).then(function (response) {
               util.sortByPropertyDesc('year', response.data.totalsByYear)
               return response.data
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, spendMetrics)
            cacheCacheKey(userId, cacheKey)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(spendMetrics)
      }

      //================================================================================

      return {
         getAccountEntries: getAccountEntries,
         downloadAccountEntries: downloadAccountEntries,
         getLastOne: getLastOne,
         makePayment: makePayment,
         getAccountEntriesCount: getAccountEntriesCount,
         getPendingPaymentsState: getPendingPaymentsState,
         getSpendMetrics: getSpendMetrics,

         // cache clearing
         clearUserCache: cacheRemoveUser,
         clear: _cache.removeAll,
      }
   }
})(angular)
