;(function (angular) {
   'use strict'

   angular.module('azure.data', [
      'algoliasearch',
      'angular-cache',
      'azure.util',
      'azure.config',
      'azureProviders',
      'azure.data.algolia',
   ])
})(angular)
