;(function (angular) {
   'use strict'

   angular.module('azure.util').directive('asLazyLoad', lazyLoad)

   function lazyLoad(util) {
      return {
         link: lazyLoadlink,
         transclude: true,
         scope: {},
         template: '<div ng-if="::lazyLoadContent" ng-transclude></div>',
      }
      function lazyLoadlink(scope, element, attrs) {
         if (util.isStringTrue(attrs.lazyLoadMaybeBypass)) {
            scope.lazyLoadContent = true
            return
         }

         var offset = attrs.lazyLoadOffset || '250px'

         if (window.IntersectionObserver) {
            initObserver()
         }

         function initObserver() {
            var observer = new window.IntersectionObserver(observerCallback, {
               rootMargin: '0px 0px ' + offset + ' 0px',
            })

            function observerCallback(entry) {
               if (entry[0].isIntersecting) {
                  // It's intersecting now, so we now need to lazy load the element.
                  // This effectively lazy loads the element.
                  scope.lazyLoadContent = true

                  // Stop observing this element (since it's been lazy loaded now, our work with this one is done).
                  observer.unobserve(element[0])
               }
            }

            observer.observe(element[0])

            scope.$on('$destroy', function () {
               observer.unobserve(element[0])
            })
         }
      }
   }
})(angular, window)
