;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('cmsData', cmsData)

   function cmsData(AzureAlgolia, config) {
      var defaultAlgoliaOptions = {
         page: 0,
         hitsPerPage: 10,
         attributesToRetrieve: 'author,excerpt,objectID,parent,primaryImage,slug,title,recipeTime,publishedDate',
      }

      // ============================================================================
      // Cache
      // ============================================================================

      var _algoliaIndex = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.cms)

      // ============================================================================
      // Exported functions
      // ============================================================================

      function search(options) {
         options = options || {}

         // Ensure there's no `query` property passed in the options object which would break the cache.
         var query = options.query
         delete options.query

         return _algoliaIndex.search(query, angular.extend({}, defaultAlgoliaOptions, options))
      }

      function searchBlog(options) {
         var facetFilters = (options.facetFilters || []).concat(['parent.id:blog'])
         return search(angular.extend({}, options, {facetFilters: facetFilters}))
      }

      function searchRecipes(options) {
         var facetFilters = (options.facetFilters || []).concat(['parent.id:recipes'])
         return search(angular.extend({hitsPerPage: 32}, options, {facetFilters: facetFilters}))
      }

      function searchVideos(options) {
         var facetFilters = (options.facetFilters || []).concat(['parent.id:videos'])
         return search(angular.extend({hitsPerPage: 32}, options, {facetFilters: facetFilters}))
      }

      return {
         search: search,
         searchBlog: searchBlog,
         searchRecipes: searchRecipes,
         searchVideos: searchVideos,
      }
   }
})(angular)
