/* jshint -W016 */
/* jshint -W040 */
;(function (angular, undefined) {
   'use strict'

   angular.module('azure.util').factory('util', util)

   function util($q, $filter, $window, $sce) {
      var _sortDirection = Object.freeze({
         ASC: 1,
         DESC: -1,
      })

      function addParamToUrl(param, url) {
         var ampOrQ = url.includes('?') ? '&' : '?'
         return url + ampOrQ + param
      }

      function absoluteUrl(pathAndSearch) {
         return $window.location.protocol + '//' + $window.location.host + pathAndSearch
      }

      function getSortByPropertyComparer(property, sortDir) {
         if (sortDir === undefined) {
            sortDir = _sortDirection.ASC
         }

         return function (a, b) {
            if (a[property] > b[property]) {
               return sortDir
            }
            if (a[property] < b[property]) {
               return -sortDir
            }
            return 0
         }
      }

      function sortByProperty(property, array) {
         array.sort(getSortByPropertyComparer(property))
         return array
      }

      function sortByPropertyDesc(property, array) {
         array.sort(getSortByPropertyComparer(property, _sortDirection.DESC))
         return array
      }

      function sortByIdDesc(array) {
         return sortByPropertyDesc('id', array)
      }

      function sortByName(array) {
         return sortByProperty('name', array)
      }

      function capitalize(string) {
         return string.charAt(0).toUpperCase() + string.slice(1)
      }

      function cleanItem(item) {
         if (item) {
            delete item.$promise
            delete item.$resolved
         }
         return item
      }

      function mapToProperty(property, items) {
         return items.map(function (item) {
            return item[property]
         })
      }

      function mapToIds(items) {
         return mapToProperty('id', items)
      }

      function first(items) {
         return items[0]
      }

      // Returns a new array with distinct items.
      function distinct(items) {
         return items.filter(function (item, i) {
            return items.indexOf(item) === i
         })
      }

      // Returns a new array with only truthy items.
      function compact(items) {
         return items.filter(function (item) {
            return item
         })
      }

      function removeById(items, id) {
         for (var i = 0; i < items.length; i++) {
            if (items[i].id === id) {
               items.splice(i, 1)
               break
            }
         }
         return items
      }

      function renameProperty(obj, oldProp, newProp) {
         if (obj && obj[oldProp]) {
            obj[newProp] = obj[oldProp]
            delete obj[oldProp]
         }
      }

      function findByPropertyValue(items, property, propertyValue) {
         return items.find(function (item) {
            return item && item[property] === propertyValue
         })
      }

      function findById(items, id) {
         return findByPropertyValue(items, 'id', id)
      }

      function findIndexByPropertyValue(items, property, propertyValue) {
         return items.findIndex(function (item) {
            return item && item[property] === propertyValue
         })
      }

      function findIndexById(items, id) {
         return findIndexByPropertyValue(items, 'id', id)
      }

      function findOne(haystack, arr) {
         // src: https://stackoverflow.com/a/25926600/1971662
         return arr.some(function (v) {
            return haystack.indexOf(v) >= 0
         })
      }

      function getPagesUntilEnd(getPageFunction, start, limit, resultsArray) {
         resultsArray = resultsArray || []
         return getPageFunction(start, limit).then(function (items) {
            resultsArray.push.apply(resultsArray, items)
            if (items.length < limit) {
               return resultsArray
            }
            return getPagesUntilEnd(getPageFunction, start + limit, limit, resultsArray)
         })
      }

      function getFilteredLength(arraySrc, filterFn) {
         if (!arraySrc || !Array.isArray(arraySrc) || typeof filterFn !== 'function') {
            return 0
         }
         return arraySrc.filter(filterFn).length
      }

      function groupBy(arr, key) {
         function getNestedProperty(obj, keyPath) {
            return keyPath.split('.').reduce((prev, curr) => {
               return prev ? prev[curr] : null
            }, obj)
         }

         var groups = {}
         for (var i = 0; i < arr.length; i++) {
            var entry = arr[i]
            var keyVal = getNestedProperty(entry, key)
            if (!groups[keyVal]) {
               groups[keyVal] = []
            }
            groups[keyVal].push(entry)
         }
         return groups
      }

      function joinArgs() {
         return Array.prototype.slice.call(arguments).join()
      }

      function mapToLowAndHigh(sourceArray) {
         return {
            low: Math.min.apply(null, sourceArray),
            high: Math.max.apply(null, sourceArray),
         }
      }

      function pennyRound(value) {
         return Math.round(value * 100) / 100
      }

      // The first argument to asyncRetryOnce should be an asynchronous function f you
      // want to retry. f should either return a promise or an object with a '$promise'
      // property.  The remaining arguments to asyncRetryOnce will be passed into f.
      // For example:
      //    retryOnce(testAsyncFunction, 1, 2)
      // will call:
      //    testAsyncFunction(1, 2)
      // This will retry on failure, unless the error.status is less than 500. In those
      // cases retrying will probably not do any good.
      function asyncRetryOnce() {
         var thisArg = this
         var f = arguments[0]

         if (typeof f !== 'function') {
            return
         }

         var args = Array.prototype.slice.call(arguments, 1)
         var firstResult = f.apply(thisArg, args)
         if (!firstResult) {
            return
         }

         return (firstResult.$promise || firstResult).catch(function (error) {
            if (0 < error.status && error.status < 500) {
               return $q.reject(error)
            }
            var retryResult = f.apply(thisArg, args)
            return retryResult.$promise || retryResult
         })
      }

      function resolveXhr(request) {
         var requestDefer = $q.defer()
         var checkRequestDone = function () {
            if (request.readyState !== XMLHttpRequest.DONE) {
               return false
            }

            if (request.status === 200) {
               requestDefer.resolve(request.responseText)
            } else {
               requestDefer.reject(request)
            }
            return true
         }
         try {
            if (!checkRequestDone()) {
               request.onreadystatechange = checkRequestDone
            }
         } catch (error) {
            console.error(error)
            requestDefer.reject(request)
         }

         return requestDefer.promise
      }

      function getPreviousSundayIfNotSunday(dateSrc) {
         // Note: If the date passed is a Sunday, the passed date is returned.
         var date = new Date(dateSrc)
         return date.setDate(date.getDate() - date.getDay())
      }

      function getFollowingSunday(dateSrc) {
         // Note: The following Sunday is always returned (even it the date passed is a Sunday).
         var date = new Date(dateSrc)
         return date.setDate(date.getDate() + (7 - date.getDay() || 7))
      }

      // Source: https://stackoverflow.com/a/7616484/6352491
      function hashString(input) {
         var hash = 0,
            i,
            chr
         if (input.length === 0) {
            return hash
         }
         for (i = 0; i < input.length; i++) {
            chr = input.charCodeAt(i)
            hash = (hash << 5) - hash + chr
            hash |= 0 // Convert to 32bit integer
         }
         return hash
      }

      function chunk(array, chunkSize) {
         var chunks = []
         for (var i = 0, len = array.length; i < len; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize))
         }
         return chunks
      }

      function isArrayOfStrings(input) {
         return (
            Array.isArray(input) &&
            input.every(function (item) {
               return typeof item === 'string'
            })
         )
      }

      function trimStart(str, char) {
         var index = 0
         while (str[index] === char && index < str.length) {
            index++
         }
         return str.substring(index)
      }

      function unfurlYouTube(str) {
         if (!str) {
            return
         }
         var youtubeUrlRegex = /<p>(&#160;|&#10;|&#9;|\s)*https:\/\/(youtu\.be\/|www.youtube\.com\/watch\?v=)([^\?#\&\s]+)(&#160;|&#10;|&#9;|\s)*<\/p>/gi
         var trustedHtml = $sce.getTrustedHtml(str)
         return $sce.trustAsHtml(
            trustedHtml.replace(
               youtubeUrlRegex,
               // prettier-ignore
               '<div class="_fluidIframeWrapper _ratio-16by9 my-5">' +
                  '<iframe class="_fluidIframe" src="https://www.youtube.com/embed/$3" frameborder="0" allowfullscreen></iframe>' +
               '</div>'
            )
         )
      }

      function isStringTrue(str) {
         return str === 'true'
      }

      function stripHtml(text) {
         return String(text).replace(/<[^>]+>/gm, '')
      }

      function isEmptyOrUndefined(arrayOrObject) {
         if (!arrayOrObject) {
            return
         }

         return !Object.keys(arrayOrObject).length
      }

      function pluralize(noun, length) {
         // Note: Currently this only handles +"s" versions of noun pluralization (i.e. not +"ies").
         // If needed for a +"ies" case, update to handle.

         if (!noun) {
            return
         }

         return length && length === 1 ? noun : noun + 's'
      }

      function columnWidthStringRatioToFloat(columnWidth) {
         // Example value for `columnWidth` is `'1/2'`
         var parts = columnWidth.split('/')
         return parseInt(parts[0]) / parseInt(parts[1])
      }

      function normalizeSearchTerm(term) {
         return term
            .toLowerCase()
            .replace(/[-\/]/gi, ' ')
            .replace(/[^\w\s]/gi, '')
      }

      //================================================================================
      // Date/Time Related
      //================================================================================

      function areDatesSameMonth(date1Src, date2Src) {
         var date1 = new Date(date1Src)
         var date2 = new Date(date2Src)
         return getMonthFromDate(date1) === getMonthFromDate(date2)
      }

      function formatTotalMinutes(totalMinutes) {
         var hours = Math.floor(totalMinutes / 60)
         var minutes = totalMinutes % 60

         function getMinutesDisplay() {
            if (minutes === 1) {
               return minutes + ' minute'
            } else if (minutes > 0) {
               return minutes + ' minutes'
            } else {
               return ''
            }
         }

         if (hours === 1) {
            return '1 hour ' + getMinutesDisplay()
         } else if (hours > 1) {
            return hours + ' hours ' + getMinutesDisplay()
         } else {
            return getMinutesDisplay()
         }
      }

      function getDateRangeForYear(year) {
         return [year + '-01-01', year + '-12-31']
      }

      function getMonthFromDate(dateObj) {
         return dateObj.toLocaleString('en-US', {
            month: 'long',
         })
      }

      function isDateNotCurrentYear(dateVal) {
         if (!dateVal) {
            return
         }
         var currentYear = new Date().getFullYear()
         var yearOfDateInQuestion = parseInt($filter('date')(dateVal, 'yyyy')) // Note: Angular's $filter returns a string
         return yearOfDateInQuestion !== currentYear
      }

      function isDateBeforeToday(dateObj) {
         if (!dateObj) {
            return
         }
         const today = new Date()
         today.setHours(0, 0, 0, 0) // Set the time of 'today' to midnight to only compare the date part

         return dateObj < today
      }

      //================================================================================

      return {
         addParamToUrl: addParamToUrl,
         absoluteUrl: absoluteUrl,
         cleanItem: cleanItem,
         mapToProperty: mapToProperty,
         mapToIds: mapToIds,
         first: first,
         distinct: distinct,
         capitalize: capitalize,
         compact: compact,
         sortDirection: _sortDirection,
         getSortByPropertyComparer: getSortByPropertyComparer,
         sortByProperty: sortByProperty,
         sortByPropertyDesc: sortByPropertyDesc,
         sortByIdDesc: sortByIdDesc,
         sortByName: sortByName,
         removeById: removeById,
         renameProperty: renameProperty,
         findByPropertyValue: findByPropertyValue,
         findIndexByPropertyValue: findIndexByPropertyValue,
         findById: findById,
         findIndexById: findIndexById,
         findOne: findOne,
         getPagesUntilEnd: getPagesUntilEnd,
         getFilteredLength: getFilteredLength,
         groupBy: groupBy,
         joinArgs: joinArgs,
         mapToLowAndHigh: mapToLowAndHigh,
         pennyRound: pennyRound,
         asyncRetryOnce: asyncRetryOnce,
         resolveXhr: resolveXhr,
         getPreviousSundayIfNotSunday: getPreviousSundayIfNotSunday,
         getFollowingSunday: getFollowingSunday,
         hashString: hashString,
         chunk: chunk,
         isArrayOfStrings: isArrayOfStrings,
         trimStart: trimStart,
         unfurlYouTube: unfurlYouTube,
         isStringTrue: isStringTrue,
         stripHtml: stripHtml,
         isEmptyOrUndefined: isEmptyOrUndefined,
         pluralize: pluralize,
         columnWidthStringRatioToFloat: columnWidthStringRatioToFloat,
         normalizeSearchTerm,
         // Date/time related
         areDatesSameMonth: areDatesSameMonth,
         formatTotalMinutes: formatTotalMinutes,
         getDateRangeForYear: getDateRangeForYear,
         getMonthFromDate: getMonthFromDate,
         isDateNotCurrentYear: isDateNotCurrentYear,
         isDateBeforeToday: isDateBeforeToday,
      }
   }
})(angular)
