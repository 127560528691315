;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('locationData', locationData)

   function locationData($q, $http, CacheFactory, config, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('locations')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyLocation = 0

      var getCacheKey = util.joinArgs

      //================================================================================

      function getIpLocation() {
         var cacheKey = getCacheKey(_keyLocation)

         var location = _cache.get(cacheKey)
         if (!location) {
            location = $http.get(config.api + '/ip2location').then(function (response) {
               return response.data
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, location)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(location)
      }

      //================================================================================

      return {
         getIpLocation: getIpLocation,
      }
   }
})(angular)
