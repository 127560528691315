;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('paymentMethodData', paymentMethodData)

   function paymentMethodData($q, $http, AzureAPI, CacheFactory, config, util) {
      //================================================================================
      // Payment Method Types
      //================================================================================

      var _types = Object.freeze({
         cashOnDelivery: 'cash-on-delivery',
         creditCard: 'credit-card',
         ach: 'ach',
         holdForPayment: 'hold-for-payment',
         net10Days: 'net-10-days',
         net30Days: 'net-30-days',
         payrollDeduction: 'payroll-deduction',
      })

      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('paymentMethods')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyPaymentMethods = 0

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cachePaymentMethod(paymentMethod) {
         var paymentMethods = _cache.get(getCacheKey(_keyPaymentMethods, paymentMethod.person))
         if (paymentMethods) {
            $q.resolve(paymentMethods).then(function (paymentMethods) {
               paymentMethods.unshift(paymentMethod)
            })
         }
      }

      function cacheRemovePaymentMethod(userId, paymentMethodId) {
         var paymentMethods = _cache.get(getCacheKey(_keyPaymentMethods, userId))
         if (paymentMethods) {
            $q.resolve(paymentMethods).then(function (paymentMethods) {
               util.removeById(paymentMethods, paymentMethodId)
            })
         }
      }

      function cacheRemoveUser(userId) {
         _cache.remove(getCacheKey(_keyPaymentMethods, userId))
      }

      //================================================================================
      // PaymentMethods
      //================================================================================

      function getActivePaymentMethods(userId, bypassCache) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyPaymentMethods, userId)
         var paymentMethods = _cache.get(cacheKey)

         if (!paymentMethods || bypassCache) {
            paymentMethods = retryOnce(AzureAPI['payment-method'].query, {
               'filter-person': userId,
               limit: config.apiLimit,
               active: true,
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            return _cache.put(cacheKey, paymentMethods)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(paymentMethods)
      }

      function getPaymentMethod(userId, paymentMethodId, bypassCache) {
         return getActivePaymentMethods(userId, bypassCache).then(function (paymentMethods) {
            var paymentMethod = paymentMethods.find(function (paymentMethod) {
               return paymentMethod.id === paymentMethodId
            })
            if (paymentMethod) {
               return paymentMethod
            }
            // don't bother caching an inactive payment method
            return retryOnce(AzureAPI['payment-method'].get, {
               id: paymentMethodId,
            }).then(util.cleanItem)
         })
      }

      function deletePaymentMethod(userId, paymentMethodId) {
         return retryOnce(AzureAPI['payment-method'].delete, {
            id: paymentMethodId,
         }).then(function () {
            cacheRemovePaymentMethod(userId, paymentMethodId)
         })
      }

      function getNmiFormSubmitUrl(apiRequestParams) {
         return AzureAPI.getNmiStepOneFormSubmitUrl(apiRequestParams)
            .then(function (response) {
               if (!response.data.formUrl) {
                  return $q.reject()
               }
               return response.data.formUrl
            })
            .catch(function (response) {
               return $q.reject(response.data)
            })
      }

      function attemptNmiStepThree(userId, tokenId) {
         return retryOnce(AzureAPI.processNmiStepThree, {
            nmiTokenId: tokenId,
            person: userId,
         })
            .then(function (response) {
               cachePaymentMethod(response.data)
               return response.data
            })
            .catch(function (response) {
               return $q.reject(response.data)
            })
      }

      function validateAch(userId, routingNumber, accountNumber, billingFirstName, billingLastName) {
         console.log(arguments)
         return retryOnce($http, {
            method: 'POST',
            url: config.beehiveApiV2 + '/accounts_receivable/validate-ach/' + userId,
            withCredentials: true,
            data: {
               routingNumber: routingNumber,
               accountNumber: accountNumber,
               firstName: billingFirstName,
               lastName: billingLastName,
            },
         }).catch(function (response) {
            return $q.reject(response.data)
         })
      }

      //================================================================================

      return {
         getPaymentMethod: getPaymentMethod,
         getActivePaymentMethods: getActivePaymentMethods,
         deletePaymentMethod: deletePaymentMethod,
         getNmiFormSubmitUrl: getNmiFormSubmitUrl,
         attemptNmiStepThree: attemptNmiStepThree,
         validateAch: validateAch,

         // Types
         types: _types,

         // cache clearing
         clearUserCache: cacheRemoveUser,
         clear: _cache.removeAll,
      }
   }
})(angular)
