;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('smallCents', smallCentsFilter)

   function smallCentsFilter($filter) {
      return function (value) {
         /* jshint eqnull: true */
         if (value == null || value.includes('%')) {
            return value
         }

         // Ensure the value is of type number
         var parsedValue = parseFloat(value.replace('$', ''))

         if (parsedValue < 1) {
            return value
         }

         var price = $filter('dollars')(parsedValue)
         var decimalIndex = price.indexOf('.')
         var dollars
         var cents
         var returnValue

         if (decimalIndex > -1) {
            dollars = price.substring(0, decimalIndex)
            cents = price.substring(decimalIndex + 1)
            returnValue = dollars + '<sup>' + cents + '</sup>'
         } else {
            returnValue = price
         }

         return returnValue
      }
   }
})(angular)
