;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('stripWhitespace', stripWhitespaceFilter)

   function stripWhitespaceFilter() {
      return function (text) {
         if (!text) {
            return
         }
         return text.replace(/[\s]/g, '')
      }
   }
})(angular)
