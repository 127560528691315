;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('addressData', addressData)

   function addressData($q, AzureAPI, CacheFactory, config, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('addresses')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyUserAddresses = 0

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheAddress(userId, address) {
         var addresses = _cache.get(getCacheKey(_keyUserAddresses, userId))
         if (addresses) {
            $q.resolve(addresses).then(function (addresses) {
               // Get index of address to update (-1 if no index found)
               var indexToUpdate = util.findIndexById(addresses, address.id)

               // If no index, address doesn't yet exist in cache... add it
               if (indexToUpdate < 0) {
                  addresses.push(address)

                  // Otherwise, update it
               } else {
                  addresses[indexToUpdate] = address
               }
            })
         }
      }

      function cacheRemoveAddress(userId, addressId) {
         var addresses = _cache.get(getCacheKey(_keyUserAddresses, userId))
         if (addresses) {
            $q.resolve(addresses).then(function (addresses) {
               util.removeById(addresses, addressId)
            })
         }
      }

      function cacheRemoveUser(userId) {
         _cache.remove(getCacheKey(_keyUserAddresses, userId))
      }

      //================================================================================
      // Addresses
      //================================================================================

      function getUserAddresses(userId) {
         var cacheKey = getCacheKey(_keyUserAddresses, userId)
         var addresses = _cache.get(cacheKey)

         if (!addresses) {
            addresses = retryOnce(AzureAPI.address.query, {
               'filter-person': userId,
               limit: config.apiLimit,
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, addresses)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(addresses).then(function (addresses) {
            // temporarily needed because addresses returned by the API that should have preference 0 instead
            // have undefined preference
            addresses.forEach(function (address) {
               if (!address.preference) {
                  address.preference = 0
               }
            })
            return util.sortByPropertyDesc('preference', addresses)
         })
      }

      function getUserAddress(userId, addressId) {
         return getUserAddresses(userId).then(function (addresses) {
            return addresses.find(function (address) {
               return address.id === addressId
            })
         })
      }

      function addAddress(userId, address) {
         return retryOnce(
            AzureAPI.address.create,
            {
               person: userId,
            },
            address
         )
            .then(util.cleanItem)
            .then(function (address) {
               cacheAddress(userId, address)
               return address
            })
      }

      function updateAddress(userId, addressId, addressData) {
         return getUserAddress(userId, addressId).then(function (address) {
            var updatedAddress = angular.extend({}, address, addressData)
            // do not allow changing the id
            updatedAddress.id = addressId
            return retryOnce(AzureAPI.address.save, updatedAddress)
               .then(util.cleanItem)
               .then(function () {
                  angular.extend(address, updatedAddress)
                  return updatedAddress
               })
         })
      }

      function deleteAddress(userId, addressId) {
         return retryOnce(AzureAPI.address.delete, {
            id: addressId,
         }).then(function () {
            cacheRemoveAddress(userId, addressId)
         })
      }

      //================================================================================

      return {
         getUserAddress: getUserAddress,
         getUserAddresses: getUserAddresses,
         addAddress: addAddress,
         deleteAddress: deleteAddress,
         updateAddress: updateAddress,

         // cache clearing
         clearUserCache: cacheRemoveUser,
         clearAddress: cacheRemoveAddress,
         clear: _cache.removeAll,
      }
   }
})(angular)
