;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('rewardData', rewardData)

   function rewardData($q, AzureAPI, CacheFactory, util, config) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('rewards')
      var _cacheKeysByUser = {}

      // cache key variables, used for building cache keys (each should be unique)
      var _keyRewardEntriesPaged = 0
      var _keyRewardEntriesCount = 1

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheRemoveUser(userId) {
         var userCacheKeys = _cacheKeysByUser[userId]
         if (userCacheKeys) {
            userCacheKeys.forEach(function (cacheKey) {
               _cache.remove(cacheKey)
            })
            delete _cacheKeysByUser[userId]
         }
      }

      function cacheCacheKey(userId, cacheKey) {
         var userCacheKeys = _cacheKeysByUser[userId] || new Set()
         _cacheKeysByUser[userId] = userCacheKeys
         userCacheKeys.add(cacheKey)
      }

      //================================================================================
      // Rewards
      //================================================================================

      function getRewardEntries(userId, bypassCache, start, limit) {
         var cacheKey = getCacheKey(_keyRewardEntriesPaged, userId, start, limit)

         var rewards = _cache.get(cacheKey)
         if (!rewards || bypassCache) {
            rewards = retryOnce(AzureAPI.reward.query, {
               start: start,
               limit: limit || config.apiLimit,
               totals: true,
               'filter-person': userId,
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, rewards)
            cacheCacheKey(userId, cacheKey)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(rewards)
      }

      function getLastOne(userId, bypassCache) {
         return getRewardEntries(userId, bypassCache, -1, 1).then(util.first)
      }

      function getRewardEntriesCount(userId) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyRewardEntriesCount, userId)

         var rewardEntriesCount = _cache.get(cacheKey)
         if (rewardEntriesCount === undefined) {
            rewardEntriesCount = retryOnce(AzureAPI.reward.count, {
               'filter-person': userId,
            }).then(function (response) {
               return response.resource.count
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, rewardEntriesCount)
            cacheCacheKey(userId, cacheKey)
         }

         return $q.resolve(rewardEntriesCount)
      }

      function getRewardsUsedOnOrder(userId, orderId) {
         return retryOnce(AzureAPI.reward.query, {
            'filter-person': userId,
            order: orderId,
            status: 'settled',
         }).then(function (rewards) {
            var debit = rewards.find(function (reward) {
               return reward.amount < 0
            })
            if (debit) {
               return -debit.amount
            }
         })
      }

      //================================================================================

      return {
         getRewardEntries: getRewardEntries,
         getLastOne: getLastOne,
         getRewardEntriesCount: getRewardEntriesCount,
         getRewardsUsedOnOrder: getRewardsUsedOnOrder,

         // cache clearing
         clearUserCache: cacheRemoveUser,
         clear: _cache.removeAll,
      }
   }
})(angular)
