;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('stripHyphens', stripHyphensFilter)

   function stripHyphensFilter() {
      return function (text) {
         return text && text.replace(/-/g, ' ')
      }
   }
})(angular)
