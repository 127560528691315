;(function (angular, Sentry) {
   'use strict'

   angular.module('azure.exceptionHandler', []).config(exceptionHandlerConfig)

   function exceptionHandlerConfig($provide) {
      if (!Sentry) {
         return
      }
      // Reporting Exceptions in Sentry
      // Adapted from https://github.com/getsentry/sentry-javascript/blob/master/packages/integrations/src/angular.ts
      var angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/

      var possiblyUnhandledPattern = /^Possibly unhandled rejection: (.*)$/
      Sentry.configureScope(function (scope) {
         scope.addEventProcessor(function (event) {
            var ex = event.exception && event.exception.values && event.exception.values[0]

            if (ex) {
               var exceptionValue = ex.value || ''
               if (!event.extra) {
                  event.extra = {}
               }

               var matches = angularPattern.exec(exceptionValue)
               if (matches) {
                  // This type now becomes something like: $rootScope:inprog
                  ex.type = matches[1]
                  ex.value = matches[2]
                  event.extra.angularDocs = matches[3]
               } else {
                  var newValue
                  if (event.extra.userErrorMessage) {
                     newValue = event.extra.userErrorMessage
                  }
                  var extraData = event.extra.data
                  if (extraData) {
                     var message
                     if (extraData.error) {
                        if (extraData.error.code) {
                           ex.type = extraData.error.code
                        }
                        message = extraData.error.message
                     }
                     if (!newValue) {
                        newValue = message || extraData.message
                     }
                  }
                  if (newValue) {
                     ex.value = newValue
                  }
               }
            }

            return event
         })
      })

      $provide.decorator('$exceptionHandler', function ($delegate) {
         return function (exception, cause) {
            if (Sentry) {
               Sentry.withScope(function (scope) {
                  if (typeof exception === 'string') {
                     var matches = possiblyUnhandledPattern.exec(exception)
                     if (matches) {
                        try {
                           var exceptionObj = JSON.parse(matches[1])
                           if (
                              exceptionObj.status === -1 ||
                              (exceptionObj.detail && exceptionObj.detail.status === -1)
                           ) {
                              // Do not report errors for requests that were aborted or timed out
                              return
                           }
                           Object.keys(exceptionObj).forEach(function (key) {
                              var value = exceptionObj[key]
                              if (key === 'config' && value.url) {
                                 scope.setTag('url', value.url)
                              } else if (key === 'status' || key === 'xhrStatus') {
                                 scope.setTag(key, value)
                              }
                              scope.setExtra(key, value)
                           })
                        } catch (error) {}
                     }
                  } else if (exception.message && exception.message.includes('load')) {
                     // Do not report errors for files failing to load
                     return
                  }

                  if (cause) {
                     scope.setExtra('cause', cause)
                  }

                  Sentry.captureException(exception)
               })
            }
            $delegate(exception, cause)
         }
      })
   }
})(angular, window.Sentry)
