;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('urlifyPhoneNumbers', urlifyPhoneNumbersFilter)

   function urlifyPhoneNumbersFilter() {
      return function (text) {
         if (!text) {
            return ''
         }

         // adapted from https://stackoverflow.com/questions/22064249/find-phone-numbers-and-give-link-using-javascript

         var regex = /((\(\d{3}\) ?)|(\b\d{3}-))?\b\d{3}-\d{4}\b/g
         var parsed = text.replace(regex, '<a href="tel:$&">$&</a>')
         return parsed
      }
   }
})(angular)
