;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('stripHtml', stripHtmlFilter)

   function stripHtmlFilter() {
      return function (text) {
         return String(text).replace(/<[^>]+>/gm, '')
      }
   }
})(angular)
