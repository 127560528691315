;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('favoriteData', favoriteData)

   function favoriteData($q, AzureAPI, CacheFactory, config, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('favorites')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyFavorites = 0

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheFavorite(userId, favorite) {
         var favorites = _cache.get(getCacheKey(_keyFavorites, userId))
         if (favorites) {
            $q.resolve(favorites).then(function (favorites) {
               favorites.push(favorite)
            })
         }
      }

      function cacheRemoveFavorite(userId, favoriteId) {
         var favorites = _cache.get(getCacheKey(_keyFavorites, userId))
         if (favorites) {
            $q.resolve(favorites).then(function (favorites) {
               util.removeById(favorites, favoriteId)
            })
         }
      }

      function cacheRemoveUser(userId) {
         _cache.remove(getCacheKey(_keyFavorites, userId))
      }

      //================================================================================
      // Favorites
      //================================================================================

      function getFavoritesPage(userId, start, limit) {
         return retryOnce(AzureAPI.favorite.query, {
            'filter-person': userId,
            limit: limit,
            start: start,
         })
      }

      function getFavorites(userId) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyFavorites, userId)

         var favorites = _cache.get(cacheKey)
         if (!favorites) {
            favorites = util.getPagesUntilEnd(getFavoritesPage.bind(null, userId), 0, config.apiLimit)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, favorites)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(favorites)
      }

      function getFavorite(userId, favoriteId) {
         return getFavorites(userId).then(function (favorites) {
            return util.findById(favorites, favoriteId)
         })
      }

      function getFavoriteByCode(userId, code) {
         return getFavorites(userId).then(function (favorites) {
            return favorites.find(function (favorite) {
               return favorite['packaged-product'] === code
            })
         })
      }

      function addFavorite(userId, code) {
         return retryOnce(AzureAPI.favorite.create, {
            'packaged-product': code,
            customer: userId,
            quantity: 1,
         })
            .then(util.cleanItem)
            .then(function (favorite) {
               cacheFavorite(userId, favorite)
               return favorite
            })
      }

      function deleteFavorite(userId, favoriteId) {
         return retryOnce(AzureAPI.favorite.delete, {
            id: favoriteId,
         }).then(function () {
            cacheRemoveFavorite(userId, favoriteId)
         })
      }

      function updateFavorite(userId, favoriteId, favoriteData) {
         return getFavorite(userId, favoriteId).then(function (favorite) {
            var updatedFavorite = angular.extend({}, favorite, favoriteData)
            return retryOnce(AzureAPI.favorite.save, updatedFavorite)
               .then(util.cleanItem)
               .then(function (response) {
                  angular.extend(favorite, response)
                  return favoriteId
               })
         })
      }

      //================================================================================

      return {
         getFavoriteByCode: getFavoriteByCode,
         getFavorites: getFavorites,
         addFavorite: addFavorite,
         deleteFavorite: deleteFavorite,
         updateFavorite: updateFavorite,

         // cache clearing
         clearUserCache: cacheRemoveUser,
         clear: _cache.removeAll,
      }
   }
})(angular)
