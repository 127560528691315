;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('currencyLeadingZerosClass', currencyLeadingZerosClass)

   function currencyLeadingZerosClass($filter) {
      return function (value, classToAdd) {
         /* jshint eqnull: true */
         if (value == null) {
            return value
         }

         var valueAsCurrency = $filter('currency')(value, '$', 2)

         return valueAsCurrency.replace(/\$(0+(\.0+)?)/, function (match) {
            return '<span class="' + classToAdd + '">' + match + '</span>'
         })
      }
   }
})(angular)
