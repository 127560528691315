;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('paymentMethodExpiration', paymentMethodExpirationFilter)

   function paymentMethodExpirationFilter() {
      return function (paymentMethod) {
         if (paymentMethod.type === 'credit-card') {
            return paymentMethod.expiration.split('-')[1] + '/' + paymentMethod.expiration.split('-')[0]
         }
      }
   }
})(angular)
