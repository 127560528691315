;(function (angular) {
   'use strict'

   angular.module('azure.filters').filter('escapeRegExp', escapeRegExp)

   function escapeRegExp() {
      return function (value) {
         /* jshint eqnull: true */
         if (value == null || typeof value !== 'string') {
            return value
         }

         // Source:
         // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
         return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }
   }
})(angular)
