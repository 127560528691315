;(function (angular) {
   'use strict'

   angular.module('azure.barcodeScanner', []).factory('barcodeScanner', barcodeScanner)

   function barcodeScanner(pubSub) {
      //================================================================================
      // Events
      //================================================================================

      var _events = Object.freeze({
         barcodeScanned: 'barcode:scanned',
      })

      function barcodeScanned(args) {
         return pubSub.publish(_events.barcodeScanned, args)
      }

      //================================================================================

      var _maxDelayBetweenKeypresses = 200
      var _currentBuffer = ''
      var _scanTimeout
      var _barcodeRegex = /\d{12,13}/g

      function checkScan() {
         var match = _currentBuffer.match(_barcodeRegex)
         _currentBuffer = ''
         if (match) {
            barcodeScanned({scannedCode: match[0], featurePath: 'scanner'})
         }
      }

      function keypress(e) {
         _currentBuffer += e.key
         clearTimeout(_scanTimeout)
         _scanTimeout = setTimeout(checkScan, _maxDelayBetweenKeypresses)
      }

      document.addEventListener('keypress', keypress)

      //================================================================================

      return {
         barcodeScanned: barcodeScanned,
         // Events
         events: _events,
         subscribe: pubSub.subscribe,
      }
   }
})(angular)
