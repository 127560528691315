/* global $ */
;(function (angular) {
   'use strict'

   /**
    * @ngdoc directive
    * @name asScrollTo
    * @description
    * # asScrollTo
    * Scroll the window to a specific target on click.
    *
    * Example usages:
    *
    * ```
    * <a as-scroll-to href="#faq">Scroll to #faq</a>
    * <button as-scroll-to="#form">Scroll to #form</button>
    * <button as-scroll-to="#form" scroll-offset="15">Scroll to 15 pixels above #form</button>
    * <button as-scroll-to>Scroll to self</button>
    * ```
    */

   angular.module('azure.util').directive('asScrollTo', scrollTo)

   function scrollTo($timeout) {
      return {
         link: link,
      }

      function link(scope, element, attrs) {
         var target
         var scrollId = attrs.asScrollTo || attrs.href
         var offset = parseInt(attrs.scrollOffset) || 0

         element.on('click', function (e) {
            e.preventDefault()

            target = scrollId ? $(scrollId) : element

            $timeout(
               function () {
                  $('html, body').animate(
                     {
                        scrollTop: target.offset().top - offset,
                     },
                     'slow'
                  )
               },
               0,
               false
            )
         })
      }
   }
})(angular)
