;(function (angular) {
   'use strict'

   angular.module('azure.util').factory('scrollPos', scrollPos)

   function scrollPos($timeout, $window, $cacheFactory) {
      //================================================================================
      // Save and restore scroll position functionality
      //================================================================================

      var _scrollPosCache = $cacheFactory('scrollPos', {
         capacity: 10,
      })

      function restoreScrollPos(url) {
         if (typeof url === 'string') {
            var scrollPosY = _scrollPosCache.get(url) || 0
            $timeout(function () {
               $window.scrollTo(0, scrollPosY)
            })
         }
      }

      function saveScrollPos(url) {
         // cache scroll position
         _scrollPosCache.put(url, $window.pageYOffset)
      }

      //================================================================================

      return {
         restoreScrollPos: restoreScrollPos,
         saveScrollPos: saveScrollPos,
      }
   }
})(angular)
