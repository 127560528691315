;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('brandData', brandData)

   function brandData($q, AzureAlgolia, AzureAPI, config, util) {
      var retryOnce = util.asyncRetryOnce

      var _brandIndex = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.brands)

      function getBrandFromApi(brandId) {
         if (!brandId) {
            return $q.resolve()
         }
         return retryOnce(AzureAPI.brand.get, {
            id: brandId,
         }).then(util.cleanItem)
      }

      function getBrand(brandId) {
         return _brandIndex.getObject(brandId).then(function (brand) {
            return brand || getBrandFromApi(brandId)
         })
      }

      function searchBrands(query, opts, cacheObjectsFromSearch) {
         return _brandIndex.search(query, opts, cacheObjectsFromSearch)
      }

      function getBrandsFromSearch(query, opts, cacheObjectsFromSearch) {
         return searchBrands(query, opts, cacheObjectsFromSearch).then(function (resp) {
            return resp.hits
         })
      }

      function getAllBrands() {
         return getBrandsFromSearch(
            null,
            {
               attributesToHighlight: '',
               hitsPerPage: 1000,
            },
            true
         )
      }

      //================================================================================

      return {
         getBrand: getBrand,
         searchBrands: searchBrands,
         getAllBrands: getAllBrands,
      }
   }
})(angular)
