;(function (angular) {
   'use strict'

   // Ensures that an `input type="number"` is formatted with two digits after the decimal on blur.

   angular.module('azure.util').directive('asCurrencyFormatInput', currencyFormatInput)

   function currencyFormatInput($filter) {
      return {
         require: 'ngModel',
         link: link,
         restrict: 'A',
         scope: {
            model: '=ngModel',
         },
      }

      function link(scope, element, attrs, ngModelCtrl) {
         ngModelCtrl.$render = function () {
            var formatted = $filter('number')(ngModelCtrl.$modelValue, 2)

            element.val(formatted)
            ngModelCtrl.$setViewValue(formatted)
         }

         element.on('blur', ngModelCtrl.$render)

         element.on('keydown', function (e) {
            if (e.key === 'Enter') {
               e.target.blur()
            }
         })
      }
   }
})(angular)
