;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('tripData', tripData)

   function tripData($q, AzureAPI, CacheFactory, util) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('trips')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyUnconfirmedTripIdsByDropId = 0
      var _keyTrip = 1

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheTrip(trip) {
         return _cache.put(getCacheKey(_keyTrip, trip.id), trip)
      }

      function cacheTrips(trips) {
         trips.forEach(cacheTrip)
         return trips
      }

      //================================================================================
      // Trips
      //================================================================================

      function getTrip(tripId, bypassCache) {
         if (!tripId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyTrip, tripId)

         var trip = _cache.get(cacheKey)
         if (!trip || bypassCache) {
            trip = retryOnce(AzureAPI.trip.get, {
               id: tripId,
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, trip)
         }
         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(trip)
      }

      function getTripsByIds(tripIds) {
         return $q.map(tripIds, function (tripId) {
            return getTrip(tripId)
         })
      }

      function getUnconfirmedTripIdsByDropId(dropId, bypassCache) {
         var cacheKey = getCacheKey(_keyUnconfirmedTripIdsByDropId, dropId)

         var dropTripIds = _cache.get(cacheKey)
         if (!dropTripIds || bypassCache) {
            // Leaving 'cutoff-after' set to three days ago to facilitate development
            // servers where trip confirmations may not happen regularly.
            // If this is a problem in production, it can be altered or removed.
            var date = new Date()
            date.setDate(date.getDate() - 3)
            dropTripIds = retryOnce(AzureAPI.trip.query, {
               drop: dropId,
               'cutoff-after': date,
               confirmed: false,
               start: -10,
            })
               .then(cacheTrips)
               .then(function (trips) {
                  var ids = util.mapToIds(trips)
                  var cacheOptions = {}
                  if (trips.length) {
                     var earliestTrip = trips[trips.length - 1]
                     var earliestTripCutoff = new Date(earliestTrip.cutoff)
                     var now = new Date()
                     if (earliestTripCutoff < now) {
                        // if the trip with earliest cutoff is in the past, expect it to be confirmed soon
                        cacheOptions.maxAge = 10 * 60 * 1000 // 10 minutes
                     } else {
                        // if no trips with cutoff in the past, expire cache 2 hours after next trip cutoff
                        earliestTripCutoff.setHours(earliestTripCutoff.getHours() + 2)
                        cacheOptions.maxAge = earliestTripCutoff.getTime() - now.getTime()
                     }
                  }
                  return _cache.put(cacheKey, ids, cacheOptions)
               })

            // cache the promise
            _cache.put(cacheKey, dropTripIds, {
               storeOnResolve: false,
            })
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(dropTripIds)
      }

      function getUnconfirmedTripsByDropId(dropId) {
         if (!dropId) {
            return $q.resolve()
         }
         return getUnconfirmedTripIdsByDropId(dropId).then(getTripsByIds)
      }

      function getNextTripOnRouteAfterCutoff(routeName, cutoffAfterDate) {
         return util
            .asyncRetryOnce(AzureAPI.trip.query, {
               route: routeName,
               'cutoff-after': cutoffAfterDate,
               start: -1,
            })
            .then(util.first)
      }

      //================================================================================

      return {
         getTrip: getTrip,
         getUnconfirmedTripIdsByDropId: getUnconfirmedTripIdsByDropId,
         getUnconfirmedTripsByDropId: getUnconfirmedTripsByDropId,
         getNextTripOnRouteAfterCutoff: getNextTripOnRouteAfterCutoff,

         // cache clearing
         clear: _cache.removeAll,
      }
   }
})(angular)
