;(function (angular) {
   'use strict'

   angular
      .module('azure.data.algolia', ['algoliasearch', 'angular-cache', 'azure.util'])
      .provider('AzureAlgolia', AzureAlgoliaProvider)

   function AzureAlgoliaProvider() {
      var apiKey
      var appId

      this.setIdAndKey = function (id, key) {
         appId = id
         apiKey = key
      }

      this.$get = function AzureAlgoliaFactory(algolia, CacheFactory, $q, util) {
         var _client = algolia.Client(appId, apiKey, {
            protocol: 'https:',
         })
         var _defaultOptions = {
            cacheSearches: {
               // clearInterval: Number N.
               // The algolia JavaScript client does not provide a way to clear individual searches from its cache.
               // Clear the search cache every N milliseconds. 0 to disable.
               clearInterval: 8 * 60 * 60 * 1000, // 8 hours
            },
            cacheObjects: {
               // maxAge: Number N.
               // Objects will expire from the cache in N milliseconds.
               maxAge: 8 * 60 * 60 * 1000, // 8 hours
            },
         }
         var _proxyIdCounter = 0

         var _maxObjectsFetch = 1000

         //================================================================================
         // Index Proxy Prototype
         //================================================================================

         var _indexProxyPrototype = {
            get index() {
               var _this = this
               if (!_this._index) {
                  _this._index = _client.initIndex(_this.indexName)
                  if (_this.options.cacheSearches && _this.options.cacheSearches.clearInterval) {
                     // If search caching is turned on and clearInterval is specified,
                     // clear the search cache every clearInterval ms.
                     setInterval(_this.clearSearchCache.bind(_this), _this.options.cacheSearches.clearInterval)
                  }
               }
               return _this._index
            },
            get cache() {
               var _this = this
               if (!_this._cache) {
                  _this._cache = CacheFactory.createCache('algolia.' + _this.id, {
                     maxAge: _this.options.cacheObjects.maxAge,
                     deleteOnExpire: 'passive',
                  })
               }
               return _this._cache
            },
            getObject: function (objectId, attributesToRetrieve) {
               return this.getObjects([objectId], attributesToRetrieve).then(util.first)
            },
            getObjects: function (objectIds, attributesToRetrieve) {
               var _this = this
               var sortedAttributes = getSortedAttributesToRetrieveString(attributesToRetrieve)
               var objectIdsNormalized = objectIds.map(function (objectId) {
                  return objectId.toString() // algolia's getObjects requires string for objectID
               })

               var objectIdMisses = []
               var objects = []

               if (_this.options.cacheObjects) {
                  objectIdsNormalized.forEach(function (objectId) {
                     var key = getCacheKey(objectId, sortedAttributes)
                     var obj = _this.cache.get(key)
                     if (obj) {
                        objects.push(obj)
                     } else {
                        objectIdMisses.push(objectId)
                     }
                  })

                  if (objectIdMisses.length === 0) {
                     return $q.resolve(objects)
                  }
               } else {
                  objectIdMisses = objectIdsNormalized
               }

               var objectIdMissesChunks = util.chunk(objectIdMisses, _maxObjectsFetch)
               return $q
                  .map(objectIdMissesChunks, function (objectIdMissesChunk) {
                     return _this.index.getObjects(objectIdMissesChunk, attributesToRetrieve).then(function (response) {
                        return response.results
                     })
                  })
                  .then(function (resultsChunks) {
                     var allResults = [].concat.apply([], resultsChunks)
                     if (_this.options.cacheObjects) {
                        _this.cacheObjects(allResults, sortedAttributes)
                     }
                     return allResults
                  })
                  .then(function (moreObjects) {
                     return objectIdsNormalized.map(function (objectId) {
                        return (
                           util.findByPropertyValue(objects, 'objectID', objectId) ||
                           util.findByPropertyValue(moreObjects, 'objectID', objectId)
                        )
                     })
                  })
            },
            search: function (query, options, cacheObjectsFromSearch) {
               var _this = this
               return _this.index.search(query, options).then(function (response) {
                  // Algolia automatically caches searches, with no option for disabling it.
                  if (!_this.options.cacheSearches) {
                     // If caching searches is turned off for this proxy,
                     // immediately clear algolia's search cache after every search.
                     _this.clearSearchCache()
                  }
                  if (_this.options.cacheObjects && cacheObjectsFromSearch) {
                     // Cache search results as objects by objectID. If getObject is going to be
                     // used to get individual items from this search using the same attributesToRetrieve,
                     // this avoids the additional algolia query since the search result objects will be
                     // cached by objectID in advance.
                     var attributesToRetrieve = options && options.attributesToRetrieve
                     var sortedAttributes = getSortedAttributesToRetrieveString(attributesToRetrieve)
                     _this.cacheObjects(response.hits, sortedAttributes)
                  }
                  return response
               })
            },
            cacheObjects: function (objects, sortedAttributesToRetrieveString) {
               var _this = this
               objects.forEach(function (obj) {
                  if (obj) {
                     var key = getCacheKey(obj.objectID, sortedAttributesToRetrieveString)
                     _this.cache.put(key, obj)
                  }
               })
               return objects
            },
            clearSearchCache: function () {
               if (this._index) {
                  this._index.clearCache()
               }
            },
            clearCache: function () {
               this.clearSearchCache()
               if (this._cache) {
                  this._cache.removeAll()
               }
            },
         }

         //================================================================================

         function getSortedAttributesToRetrieveString(attributesToRetrieve) {
            var attributes
            if (typeof attributesToRetrieve === 'string') {
               attributes = attributesToRetrieve.split(',')
            } else if (Array.isArray(attributesToRetrieve)) {
               attributes = attributesToRetrieve.slice()
            }
            if (attributes) {
               return attributes.sort().join()
            }
         }

         function createIndexProxy(indexName, options) {
            if (!indexName) {
               throw new Error('indexName is required.')
            }

            var opts = angular.merge({}, _defaultOptions, options)

            return Object.create(_indexProxyPrototype, {
               id: {
                  value: ++_proxyIdCounter,
               },
               indexName: {
                  value: indexName,
               },
               options: {
                  value: opts,
               },
            })
         }

         function getCacheKey(objectId, qualifier) {
            return objectId + (qualifier ? '-' + qualifier : '')
         }

         //================================================================================

         return {
            client: _client,
            createIndexProxy: createIndexProxy,
         }
      }
   }
})(angular)
