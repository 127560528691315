;(function (angular) {
   'use strict'

   angular.module('azure.data').factory('countryData', countryData)

   function countryData($q, $filter, AzureAPI, $cacheFactory, config, util) {
      var USA = 'UNITED STATES'
      var CAN = 'CANADA'
      var cache = $cacheFactory('countries')

      var retryOnce = util.asyncRetryOnce

      function sortForDisplay(countries) {
         // Put USA first, CAN second, then the rest.
         var usaIndex = countries.indexOf(
            $filter('filter')(countries, {
               name: USA,
            })[0]
         )
         countries.splice(0, 0, countries.splice(usaIndex, 1)[0])
         var canIndex = countries.indexOf(
            $filter('filter')(countries, {
               name: CAN,
            })[0]
         )
         countries.splice(1, 0, countries.splice(canIndex, 1)[0])
         return countries
      }

      function getCountries() {
         var countries = cache.get('countries')

         if (!countries) {
            countries = retryOnce(AzureAPI.country.query, {
               limit: config.apiLimit,
            })
               .then(sortForDisplay)
               .then(function (countries) {
                  // when promise resolves, replace cached promise with resolved object
                  return cache.put('countries', countries)
               })

            // cache the promise
            return cache.put('countries', countries)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(countries)
      }

      return {
         getCountries: getCountries,
      }
   }
})(angular)
