// src: https://stackoverflow.com/a/31345384/1971662

;(function (angular) {
   'use strict'

   angular.module('azure.validation').directive('asSameAs', sameAs)

   function sameAs() {
      return {
         require: 'ngModel',
         restrict: 'A',
         link: function (scope, element, attrs, ctrl) {
            var modelToMatch = attrs.asSameAs

            scope.$watch(attrs.asSameAs, function () {
               ctrl.$validate()
            })

            ctrl.$validators.match = function (modelValue, viewValue) {
               return viewValue === scope.$eval(modelToMatch)
            }
         },
      }
   }
})(angular)
