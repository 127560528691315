;(function (angular) {
   'use strict'

   angular.module('azure.util').directive('asProductImpressionIsolateScope', productImpressionIsolateScope)

   function productImpressionIsolateScope() {
      return {
         scope: {
            productId: '@',
            position: '@',
            productList: '@',
            featurePath: '@',
         },
         transclude: true,
         template: '<div ng-transclude as-product-impression></div>',
      }
   }
})(angular)
