;(function (angular, undefined) {
   'use strict'

   angular.module('azure.data').factory('productData', productData)

   function productData($q, AzureAPI, AzureAlgolia, config, util, CacheFactory) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('products')
      var _packagedProductCache = CacheFactory.createCache('packagedProducts', {
         maxAge: 30 * 60 * 1000, // 30 minutes
      })

      // cache key variables, used for building cache keys (each should be unique)
      var _keyProduct = 0
      var _keyProductByCode = 1
      var _keyProductByGtin13 = 2
      var _keyPackagedProduct = 3
      var _keyPackagedProductsByCodePromise = 4

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheProduct(product, byCode, full) {
         if (!product) {
            return
         }
         _cache.put(getCacheKey(_keyProduct, product.id, full), product)
         if (byCode) {
            product.packaging.forEach(function (packaging) {
               _cache.put(getCacheKey(_keyProductByCode, packaging.code), product)
            })
         }
         return product
      }

      function cacheProducts(products, byCode, full) {
         return products.map(function (product) {
            return cacheProduct(product, byCode, full)
         })
      }

      function replacePackagedProduct(product, packagedProduct) {
         var index = util.findIndexByPropertyValue(product.packaging, 'code', packagedProduct.code)
         if (index >= 0) {
            product.packaging[index] = packagedProduct
         }
      }

      function cachePackagedProduct(packagedProduct) {
         _packagedProductCache.put(getCacheKey(_keyPackagedProduct, packagedProduct.code), packagedProduct)
         var productByCodeCacheKey = getCacheKey(_keyProductByCode, packagedProduct.code)
         var cachedProductByCode = _cache.get(productByCodeCacheKey)
         if (cachedProductByCode) {
            replacePackagedProduct(cachedProductByCode, packagedProduct)
         }
         var cachedProduct =
            _cache.get(getCacheKey(_keyProduct, packagedProduct.product, false)) ||
            _cache.get(getCacheKey(_keyProduct, packagedProduct.product, true))
         if (cachedProduct) {
            replacePackagedProduct(cachedProduct, packagedProduct)
         }
      }

      function cachePackagedProducts(packagedProducts) {
         packagedProducts.forEach(cachePackagedProduct)
         return packagedProducts
      }

      //================================================================================
      // Sort By
      //================================================================================

      var _sortBy = Object.freeze({
         name: 'name',
         brand: 'brand',
         favorites: 'favorites',
         relevance: 'relevance',
         orderFrequency: 'orderFrequency',
         orderRecency: 'orderRecency',
      })

      var _productCodes = Object.freeze({
         salesFlyer: 'CT005',
      })

      //================================================================================
      // Algolia Index Setup
      //================================================================================

      var _productIndex = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.products, {
         cacheObjects: false,
      })
      var _productIndexes = {}
      _productIndexes[_sortBy.name] = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.productsByName)
      _productIndexes[_sortBy.brand] = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.productsByBrand)
      _productIndexes[_sortBy.favorites] = AzureAlgolia.createIndexProxy(
         config.algoliaIndexNames.productsByFavoritesDesc
      )
      _productIndexes[_sortBy.relevance] = _productIndex
      _productIndexes[_sortBy.orderFrequency] = _productIndex
      _productIndexes[_sortBy.orderRecency] = _productIndex

      // Create an index proxy for searches that aren't cached
      var _noCacheProductIndex = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.products, {
         cacheSearches: false,
         cacheObjects: false,
      })

      // The inline options when getting products by id from the API
      var _apiInline = 'brand,packaging.favorites,packaging.next-purchase-arrival,substitutions,favorites'

      var _apiInlineFull =
         'brand,packaging.favorites,packaging.next-purchase-arrival,substitutions,favorites,description,links'

      // The default product attributes to retrieve when getting products from Algolia
      var _defaultAlgoliaAttributes = [
         'id',
         'brand.name',
         'name',
         'substitutions',
         'favorites',
         'storageClimate',
         'slug',
         'isShippableUps',
         'maxStorageDays',
         'treatAsActive',
         'unshippableRegions',
         'packaging.code',
         'packaging.price',
         'packaging.weight',
         'packaging.volume',
         'packaging.tags',
         'packaging.images',
         'packaging.size',
         'packaging.stock',
         'packaging.next-purchase-arrival',
         'packaging.favorites',
         'packaging.bargain-bin-notes',
         'packaging.rewardsEnabled',
         'packaging.trustpilotNumberOfReviews',
         'packaging.trustpilotStarsAverage',
         'packaging.freightHandlingRequired',
      ]

      // The product attributes to retrieve when getting products by gtin13 from Algolia
      var _byGtin13AlgoliaAttributes = ['id', 'brand.name', 'name', 'slug', 'packaging.code', 'packaging.gtin13']

      //================================================================================
      // Product Query Regular Expressions
      //================================================================================

      // Support gtin12 or gtin13 searches
      var _gtinRegex = /^\d{12,13}$/
      var _codeRegex = /^([a-z]{2}\d{3,}|gift\d{2,}|[a-c|e-z][a-z]{2,}\d{3,})$/i
      var _idRegex = /^\d{4,11}$/

      //================================================================================
      // Products
      //================================================================================

      function findPackagingByCode(product, code) {
         return product.packaging.find(function (packagedProduct) {
            return packagedProduct.code === code
         })
      }

      function productIfMatchingCode(product, code) {
         if (!product) {
            return
         }

         if (!code || findPackagingByCode(product, code)) {
            return product
         }
      }

      function getProductFromApi(productIdAndCode, full) {
         if (!productIdAndCode) {
            return $q.resolve()
         }

         full = !!full

         return retryOnce(AzureAPI.product.get, {
            id: productIdAndCode.id,
            inline: full ? _apiInlineFull : _apiInline,
         })
            .then(util.cleanItem)
            .then(function (product) {
               cacheProduct(product, false, full)
               return productIfMatchingCode(product, productIdAndCode.code)
            })
      }

      function getProductFromAlgolia(productIdAndCode, full) {
         var attributes = full ? undefined : _defaultAlgoliaAttributes
         return _productIndex.getObject(productIdAndCode.id, attributes).then(function (product) {
            cacheProduct(product, false, full)
            return productIfMatchingCode(product, productIdAndCode.code)
         })
      }

      function getProduct(productIdAndCode, full) {
         if (!productIdAndCode) {
            return $q.resolve()
         }

         full = !!full

         var cacheKey = getCacheKey(_keyProduct, productIdAndCode.id, full)
         var product = productIfMatchingCode(_cache.get(cacheKey), productIdAndCode.code)
         if (!product && !full) {
            product = productIfMatchingCode(
               _cache.get(getCacheKey(_keyProduct, productIdAndCode.id, true)),
               productIdAndCode.code
            )
         }

         if (!product) {
            product = getProductFromAlgolia(productIdAndCode, full).then(function (product) {
               return product || getProductFromApi(productIdAndCode, full)
            })
         }

         return $q.resolve(product)
      }

      function getProductsFromApi(productIdsAndCodes) {
         var productIds = util.mapToIds(productIdsAndCodes)
         var codes = util.mapToProperty('code', productIdsAndCodes)
         return retryOnce(AzureAPI.product.query, {
            id: productIds.join(),
            'packaged-product': codes.join(),
            inline: _apiInline,
            limit: config.apiLimit,
         }).then(function (products) {
            cacheProducts(products, false, false)
            return getProductsByIdDictionary(productIdsAndCodes, products)
         })
      }

      function getProductsFromAlgolia(productIdsAndCodes) {
         var productIds = util.mapToIds(productIdsAndCodes)
         return _productIndex.getObjects(productIds, _defaultAlgoliaAttributes).then(function (products) {
            cacheProducts(products, false, false)
            return getProductsByIdDictionary(productIdsAndCodes, products)
         })
      }

      function getProducts(productIdsAndCodes, noApiFallback) {
         var cacheResult = {
            notFound: [],
            productsById: {},
         }
         productIdsAndCodes.forEach(function (idAndCode) {
            var product =
               _cache.get(getCacheKey(_keyProduct, idAndCode.id, false)) ||
               _cache.get(getCacheKey(_keyProduct, idAndCode.id, true))

            product = productIfMatchingCode(product, idAndCode.code)
            if (product) {
               cacheResult.productsById[product.id] = product
            } else if (idAndCode.id) {
               cacheResult.notFound.push(idAndCode)
            }
         })

         if (cacheResult.notFound.length === 0) {
            return $q.resolve(cacheResult)
         }

         var cacheAndAlgoliaResultPromise = getProductsFromAlgolia(cacheResult.notFound).then(function (algoliaResult) {
            return {
               notFound: algoliaResult.notFound,
               productsById: angular.extend(cacheResult.productsById, algoliaResult.productsById),
            }
         })

         return cacheAndAlgoliaResultPromise.then(function (cacheAndAlgoliaResult) {
            if (cacheAndAlgoliaResult.notFound.length === 0 || noApiFallback) {
               return cacheAndAlgoliaResult
            }
            return getProductsFromApi(cacheAndAlgoliaResult.notFound).then(function (apiResult) {
               return {
                  notFound: apiResult.notFound,
                  productsById: angular.extend(cacheAndAlgoliaResult.productsById, apiResult.productsById),
               }
            })
         })
      }

      function checkAlgolia(productIdsAndCodes, priceLevel) {
         var productIds = util.mapToIds(productIdsAndCodes)
         return _noCacheProductIndex
            .getObjects(productIds, ['id,packaging.code,packaging.price'])
            .then(function (products) {
               var found = []
               var notFound = []
               productIdsAndCodes.forEach(function (productIdAndCode) {
                  var productId = Number(productIdAndCode.id)
                  var product = util.findById(products, productId)
                  var pack = product && findPackagingByCode(product, productIdAndCode.code)
                  if (pack && pack.price[priceLevel]) {
                     found.push(productIdAndCode)
                  } else {
                     notFound.push(productIdAndCode)
                  }
               })
               return {
                  found: found,
                  notFound: notFound,
               }
            })
      }

      function searchProducts(sortBy, query, opts, maybeCacheProducts) {
         sortBy = sortBy || _sortBy.relevance
         var indexToSearch = _productIndexes[sortBy]
         return indexToSearch.search(query, opts).then(function (response) {
            if (maybeCacheProducts) {
               cacheProducts(response.hits, false, false)
            }
            return response
         })
      }

      function findProductByCode(products, code) {
         return products.find(function (product) {
            return productIfMatchingCode(product, code)
         })
      }

      function getProductsByIdDictionary(productIdsAndCodes, products) {
         var productsById = {}
         var notFound = []
         productIdsAndCodes.forEach(function (idAndCode) {
            var productId = Number(idAndCode.id)
            var product = productIfMatchingCode(util.findById(products, productId), idAndCode.code)
            if (product) {
               productsById[productId] = product
            } else {
               notFound.push(idAndCode)
            }
         })
         return {
            productsById: productsById,
            notFound: notFound,
         }
      }

      function getProductsByCodeDictionary(codes, products) {
         var productsByCode = {}
         var notFound = []
         codes.forEach(function (code) {
            var product = findProductByCode(products, code)
            if (product) {
               productsByCode[code] = product
            } else {
               notFound.push(code)
            }
         })
         return {
            productsByCode: productsByCode,
            notFound: notFound,
         }
      }

      function getPackagedProductsByCodeDictionary(codes, packagedProducts) {
         var packagedProductsByCode = {}
         var notFound = []
         codes.forEach(function (code) {
            var packagedProduct = util.findByPropertyValue(packagedProducts, 'code', code)
            if (packagedProduct) {
               packagedProductsByCode[code] = packagedProduct
            } else {
               notFound.push(code)
            }
         })
         return {
            packagedProductsByCode: packagedProductsByCode,
            notFound: notFound,
         }
      }

      function getProductsByCodeFromApi(codes) {
         return retryOnce(AzureAPI.product.query, {
            'packaged-product': codes.join(),
            inline: _apiInline,
            limit: config.apiLimit,
         }).then(function (products) {
            cacheProducts(products, true, false)
            return getProductsByCodeDictionary(codes, products)
         })
      }

      function getProductsByCodeFromAlgolia(codes) {
         var algoliaParameters = {
            hitsPerPage: codes.length,
            attributesToRetrieve: _defaultAlgoliaAttributes.join(),
            attributesToHighlight: '',
            filters: codes
               .map(function (code) {
                  return 'packaging.code:' + code
               })
               .join(' OR '),
         }
         return _noCacheProductIndex.search(algoliaParameters).then(function (response) {
            var products = response.hits
            cacheProducts(products, true, false)
            return getProductsByCodeDictionary(codes, products)
         })
      }

      function getProductsByCode(codes, skipAlgolia) {
         var cacheResult = {
            notFound: [],
            productsByCode: {},
         }

         codes.forEach(function (code) {
            var product = _cache.get(getCacheKey(_keyProductByCode, code))
            if (product) {
               cacheResult.productsByCode[code] = product
            } else {
               cacheResult.notFound.push(code)
            }
         })

         var cacheResultPromise = $q.resolve(cacheResult)

         if (cacheResult.notFound.length === 0) {
            return cacheResultPromise
         }

         var cacheAndAlgoliaResultPromise
         if (skipAlgolia) {
            cacheAndAlgoliaResultPromise = cacheResultPromise
         } else {
            cacheAndAlgoliaResultPromise = getProductsByCodeFromAlgolia(cacheResult.notFound).then(function (
               algoliaResult
            ) {
               return {
                  notFound: algoliaResult.notFound,
                  productsByCode: angular.extend(cacheResult.productsByCode, algoliaResult.productsByCode),
               }
            })
         }

         return cacheAndAlgoliaResultPromise.then(function (cacheAndAlgoliaResult) {
            if (cacheAndAlgoliaResult.notFound.length === 0) {
               return cacheAndAlgoliaResult
            }
            return getProductsByCodeFromApi(cacheAndAlgoliaResult.notFound).then(function (apiResult) {
               return {
                  notFound: apiResult.notFound,
                  productsByCode: angular.extend(cacheAndAlgoliaResult.productsByCode, apiResult.productsByCode),
               }
            })
         })
      }

      function getProductByCode(code, skipAlgolia) {
         return getProductsByCode([code], skipAlgolia).then(function (result) {
            return result.productsByCode[code]
         })
      }

      function getProductByGtin13(gtin13) {
         // TODO: add fall back to API
         var cacheKey = getCacheKey(_keyProductByGtin13, gtin13)
         var product = _cache.get(cacheKey)
         if (!product) {
            var algoliaParameters = {
               hitsPerPage: 1,
               attributesToRetrieve: _byGtin13AlgoliaAttributes.join(),
               attributesToHighlight: '',
               filters: 'packaging.gtin13:' + gtin13,
            }
            product = _noCacheProductIndex.search(algoliaParameters).then(function (response) {
               var products = response.hits
               if (products.length) {
                  return products[0]
               }
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, product)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(product)
      }

      function getProductByQuery(query) {
         var maybeProductPromise

         if (_idRegex.test(query)) {
            maybeProductPromise = getProduct({id: query})
         } else if (_codeRegex.test(query)) {
            var code = query.toUpperCase()
            maybeProductPromise = getProductByCode(code).then(function (product) {
               if (product) {
                  product.codes = [code]
               }
               return product
            })
         } else if (_gtinRegex.test(query)) {
            var gtin13 = query.length === 12 ? '0' + query : query
            maybeProductPromise = getProductByGtin13(gtin13).then(function (product) {
               if (product) {
                  var packaging = product.packaging.filter(function (packagedProduct) {
                     return packagedProduct.gtin13 === gtin13
                  })
                  product.codes = util.mapToProperty('code', packaging)
               }
               return product
            })
         }

         return $q.resolve(maybeProductPromise)
      }

      function getPackagedProductByOldId(id) {
         return retryOnce(AzureAPI['packaged-product'].old, {
            id: id,
         }).then(util.cleanItem)
      }

      function getPackagedProductsByCodeFromApi(codes) {
         var joinedCodes = codes.join()
         var cacheKey = getCacheKey(_keyPackagedProductsByCodePromise, joinedCodes)
         var promise = _cache.get(cacheKey)
         if (!promise) {
            promise = retryOnce(AzureAPI['packaged-product'].query, {
               code: joinedCodes,
               inline: 'next-purchase-arrival,favorites',
               limit: config.apiLimit,
            })
            _cache.put(cacheKey, promise)
         }

         return promise.then(cachePackagedProducts).then(function (packagedProducts) {
            _cache.remove(cacheKey)
            return getPackagedProductsByCodeDictionary(codes, packagedProducts)
         })
      }

      function getPackagedProductsByCode(codes) {
         var cacheResult = {
            notFound: [],
            packagedProductsByCode: {},
         }

         codes.forEach(function (code) {
            var packagedProduct = _packagedProductCache.get(getCacheKey(_keyPackagedProduct, code))
            if (packagedProduct) {
               cacheResult.packagedProductsByCode[code] = packagedProduct
            } else {
               cacheResult.notFound.push(code)
            }
         })

         if (cacheResult.notFound.length === 0) {
            return $q.resolve(cacheResult)
         }

         return getPackagedProductsByCodeFromApi(cacheResult.notFound).then(function (apiResult) {
            return {
               notFound: apiResult.notFound,
               packagedProductsByCode: angular.extend(
                  cacheResult.packagedProductsByCode,
                  apiResult.packagedProductsByCode
               ),
            }
         })
      }

      function getAlgoliaStockFilters(pickDate) {
         var inStockFilters = 'packaging.stock > 0'
         var outOfStockFilters = 'packaging.stock = 0'
         if (pickDate) {
            var pickDateTimestamp = Math.round(pickDate.getTime() / 1000)
            var today = new Date()
            today.setHours(0, 0, 0, 0)
            var todayTimestamp = Math.round(today.getTime() / 1000)
            var arrivalFilter =
               'packaging.next-purchase-arrival-timestamp: ' + todayTimestamp + ' TO ' + pickDateTimestamp
            inStockFilters = '(' + inStockFilters + ' OR ' + arrivalFilter + ')'
            outOfStockFilters += ' AND NOT ' + arrivalFilter
         }
         return {
            inStock: inStockFilters,
            outOfStock: outOfStockFilters,
         }
      }

      //================================================================================

      return {
         algoliaPaginationLimitedTo: 2000, // This matches a setting on the algolia product indices
         defaultAlgoliaAttributes: _defaultAlgoliaAttributes,

         checkAlgolia: checkAlgolia,
         getProduct: getProduct,
         getProducts: getProducts,
         searchProducts: searchProducts,
         getProductsByCode: getProductsByCode,
         getProductByCode: getProductByCode,
         getProductByQuery: getProductByQuery,
         getPackagedProductByOldId: getPackagedProductByOldId,
         getPackagedProductsByCode: getPackagedProductsByCode,
         getAlgoliaStockFilters: getAlgoliaStockFilters,

         // Sort By:
         sortBy: _sortBy,

         // Product Codes
         productCodes: _productCodes,
      }
   }
})(angular)
